import api from "../../../services/api";
import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, SectionHeader, SectionContainer, Text, Box } from "../../W3libComponents";

export const CompanyList = ({ context }) => {

  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const { setLoading, goTo, checkPermission } = context

  useEffect(() => {
    setLoading(true)
    api
      .get(`/company`)
      .then((response) => {
        setLoading(false)
        setCompanies(response.data.companies);
      })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      });
  }, []);

  return (
    <SectionContainer>
      <SectionHeader
        title="Empresas"
        newButtonAction={() => history.push(`/company/new`)}
        newButton={checkPermission(['company-create'])}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ width: 80 }}>
                
              </TableCell>
              <TableCell align="left" style={{ fontWeight: "bold" }}>
                Nome da Empresa
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company, index) => (
              <CompanyListRow company={company} key={`companylistrow${index}`} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SectionContainer>
  );
};

const CompanyListRow = ({ company }) => {

  const history = useHistory();
  const handleUser = useCallback(
    (id) => history.push(`/company/${id}`),
    [history]
  );

  return (
    <TableRow
      hover
      sx={{ cursor: "pointer" }}
      onClick={() => handleUser(company?._id)}
    >
      <TableCell align="left" aria-label="Logo">
        <Box sx={{ height: 60, width: 60, border: '1px solid lightgray', padding: 1, borderRadius: 2, }}>
          {company?.urlLogo &&
            <img src={company.urlLogo} alt="Logo da Empresa" style={{ width: '100%', height: '100%' ,objectFit: 'contain' }} />
          }
          {!company.urlLogo &&
            <img src="/company-default.png" alt="Logo genérica" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          }
        </Box>
      </TableCell>
      <TableCell align="left" aria-label="Nome">
        <Text style={{ fontWeight: 500, textAlign: 'left' }}>{company?.name}</Text>
      </TableCell>
    </TableRow>
  );
};
