import { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Text,
  TextField,
  ErrorDialog,
  ConfirmDialog,
} from "../../W3libComponents";

export const ChangePasswordDialog = (props) => {
  const {
    setLoading,
    handleClose,
    confimButtonLabel = "Confirmar",
    cancelButtonLabel = "Cancelar",
    question,
    title = "w3lib Admin",
  } = props;
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [checkErrors, setCheckErrors] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [resetData, setResetData] = useState({
    password: "",
    newPassword: "",
    confirmationPassword: "",
  });

  useEffect(() => {
    if (checkErrors) validateFields();
  }, [resetData]);

  const handleChangePassword = (resetData) => {
    const { password, newPassword, confirmationPassword } = resetData;
    console.log(resetData)
    setLoading(true);

    api
      .patch("/account/password", {
        password: password,
        newPassword: newPassword,
        confirmation_password: confirmationPassword,
      })
      .then((response) => {
        handleClose()
        setShowConfirmDialog(true);
        setLoading(false);
      })
      .catch((err) => {
        handleError(err.response.data.error);
      });
  };

  const handleError = (err) => {
    setError(err);
    setLoading(false);
  };

  const validateFields = () => {
    const { password, newPassword, confirmationPassword } = resetData;

    let newErrors = {};

    if (password.length < 6)
      newErrors["password"] = {
        error: 'O campo "Senha" deve conter no mínimo 6 caracteres',
      };

    if (newPassword.length < 6)
      newErrors["newPassword"] = {
        error: 'O campo "Nova Senha" deve conter no mínimo 6 caracteres',
      };

    if (confirmationPassword.length < 6)
      newErrors["confirmationPassword"] = {
        error: "A confirmação da senha é obrigatória",
      };

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setResetData({
      ...resetData,
      [name]: value,
    });
  };

  return (
    <>
      <Dialog open={true} onClose={handleClose}>
        <Text style={{ padding: 10, fontSize: 20, fontWeight: 700 }}>
          {title}
        </Text>
        <DialogContent>
          <DialogContentText>
            {question}
            <TextField
              label="Senha Atual"
              variant="outlined"
              name="password"
              onChange={handleChange}
              type="password"
              required
              error={errors?.password}
              helperText={errors?.password?.error}
            ></TextField>
            <TextField
              label="Nova Senha"
              variant="outlined"
              name="newPassword"
              onChange={handleChange}
              type="password"
              required
              error={errors?.newPassword}
              helperText={errors?.newPassword?.error}
            ></TextField>
            <TextField
              label="Confirmar Nova Senha"
              variant="outlined"
              name="confirmationPassword"
              onChange={handleChange}
              type="password"
              required
              error={errors?.confirmationPassword}
              helperText={errors?.confirmationPassword?.error}
            ></TextField>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            {cancelButtonLabel}
          </Button>
          <Button
            onClick={() => {
              setCheckErrors(true);
              if (!validateFields()) return false;
              handleChangePassword(resetData);
            }}
            color="primary"
            variant="contained"
          >
            {confimButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      {error && (
        <ErrorDialog
          msg={error}
          title="w3lib Admin"
          handleClose={() => setError(null)}
        />
      )}
      {showConfirmDialog && (
        <ConfirmDialog
          question={"Senha atualizada com Sucesso!"}
          title="w3lib Admin"
          handleConfirm={() => setShowConfirmDialog(false)}
          cancelButton={false}
        />
      )}
    </>
  );
};
