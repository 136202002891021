import { useEffect, useState, useContext } from 'react'
import moment from 'moment'
import { Paper, Box, Text, TextField, DateRangeInput, FormControl, InputLabel, Select, MenuItem, Button } from '../../W3libComponents'
import { filterByKey, filterParticipants } from '../../../utils'
import { AppContext } from '../../../contexts'
import { tasksOrderBy } from '../../../utils/tasks'

const taskPriority = {
  '1': 'Baixa',
  '2': 'Normal',
  '3': 'Alta',
  '4': 'Urgência',
}

export const TaskFilters = ({ tasks = [], filteredTasks, setFilteredTasks, orderBy, taskUsers = [] }) => {

  const filtersInitialState = {
    query: '',
    createdBy: '',
    company: '',
    assignTo: '',
    status: 'open',
    participants: '',
    startDate: null,
    endDate: null,
    priority: ''
  }

  const { setLoading } = useContext(AppContext);
  const [filters, setFilters] = useState(filtersInitialState)

  useEffect(() => {
    if (window.localStorage.getItem('w3lib-admin-filters')) {
      const localfilters = JSON.parse(window.localStorage.getItem('w3lib-admin-filters'))
      setFilters(localfilters)
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('w3lib-admin-filters', JSON.stringify(filters));
  }, [filters]);

  const handleFilter = ({ target }) => {
    const { name, value } = target
    setFilters({ ...filters, [name]: value })
  }

  const handleChangeDate = (value) => {
    setFilters({
      ...filters,
      startDate: value[0],
      endDate: value[1]
    })
  }

  useEffect(() => {
    setLoading(true)
    let newTasks = tasks.filter(task => {
      if (task.title.toLowerCase().includes(filters.query.toLowerCase()))
        return task

      if (task.number == parseInt(filters.query))
        return task

    })
      .filter(task => {
        if (filters.startDate) {
          return moment(new Date(task.createdAt)).format('YYYY-MM-DD 00:00:00') >= moment(new Date(filters.startDate)).subtract(1, 'days').format('YYYY-MM-DD 00:00:00')
        }
        return task
      })
      .filter(task => {
        if (filters.endDate) {
          return moment(new Date(task.createdAt)).format('YYYY-MM-DD 00:00:00') <= moment(new Date(filters.endDate)).format('YYYY-MM-DD 23:59:59')
        }
        return task
      })
      .filter(task => {
        if (filters.priority) return task.priority == filters.priority;
        return task
      })

    newTasks = filterByKey(newTasks, 'createdBy._id', filters.createdBy)
    newTasks = filterByKey(newTasks, 'company._id', filters.company)
    newTasks = filterByKey(newTasks, 'assignTo._id', filters.assignTo)
    newTasks = filterParticipants(newTasks, 'participants._id', filters.participants)
    newTasks = filterByKey(newTasks, 'status', filters.status)

    const proprietyOrderBy = orderBy
    const nameOrderBy = Object.keys(orderBy)[0]
    tasksOrderBy(newTasks, proprietyOrderBy, nameOrderBy)

    setFilteredTasks(newTasks)
    setLoading(false)
  }, [tasks, filters, orderBy])

  return (
    <Paper sx={{ padding: 2, marginBottom: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
        <Text sx={{ fontWeight: '700' }}>Filtros</Text>
        <Box sx={{ display: 'flex', gap: 0.5, color: '#999', fontSize: 15 }}>
          <Text sx={{ fontSize: 'inherit' }}>Mostrando</Text>
          <Text sx={{ fontSize: 'inherit', fontWeight: '700' }}>{filteredTasks.length}</Text>
          <Text sx={{ fontSize: 'inherit' }}>de</Text>
          <Text sx={{ fontSize: 'inherit', fontWeight: '700' }}>{tasks.length}</Text>
          <Text sx={{ fontSize: 'inherit' }}>chamados</Text>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginTop: 1 }}>
        <TextField
          size='small'
          name='query'
          placeholder='Busque pelo nome ou número do chamado'
          onChange={handleFilter}
          sx={{ margin: 0, marginRight: 1 }}
        />
        <DateRangeInput handleChangeDate={handleChangeDate} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
        <FormControl size='small' fullWidth sx={{ width: '8rem', minWidth: 150, marginRight: 1 }}>
          <InputLabel id="priorityLabelselect">Prioridade</InputLabel>
          <Select
            size='small'
            name="priority"
            labelId="priorityLabelselect"
            id="priority-select"
            value={filters.priority}
            label="Prioridade"
            onChange={handleFilter}
          >
            <MenuItem value={null}>Todas</MenuItem>
            {
              [...new Set(tasks.map(task => task.priority))].sort().map((task, index) => (
                // <MenuItem value={task.priority} key={`priority${task.priority}${index}`}>{task.priority}</MenuItem>
                <MenuItem value={task} key={`priority${task}${index}`}>{taskPriority[task]}</MenuItem>

              ))
            }
          </Select>
        </FormControl>
        <FormControl size='small' fullWidth sx={{ width: '8rem', minWidth: 150, marginRight: 1 }}>
          <InputLabel id="createdByLabelselect">Autor</InputLabel>
          <Select
            size='small'
            name="createdBy"
            labelId="createdByLabelselect"
            id="createdBy-select"
            value={filters.createdBy}
            label="Autor"
            onChange={handleFilter}
          >
            <MenuItem value=''>Todos</MenuItem>
            {
              [...new Map(tasks.map(task => task.createdBy).map(item =>
                [item['_id'], item])).values()
              ].map(task => (
                <MenuItem value={task._id} key={`createdBy${task._id}`}>{task.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <FormControl size='small' fullWidth sx={{ width: '8rem', minWidth: 150, marginRight: 1 }}>
          <InputLabel id="companyLabelselect">Cliente</InputLabel>
          <Select
            size='small'
            name="company"
            labelId="companyLabelselect"
            id="company-select"
            value={filters.company}
            label="Cliente"
            // size="small"
            onChange={handleFilter}
          >
            <MenuItem value=''>Todos</MenuItem>
            {
              [...new Map(tasks.map(task => task.company).map(item =>
                [item['_id'], item])).values()
              ]
                .map(task => (
                  <MenuItem value={task._id} key={`company${task._id}`}>{task.name}</MenuItem>
                ))
            }
          </Select>
        </FormControl>
        <FormControl size='small' fullWidth sx={{ width: '8rem', minWidth: 150, marginRight: 1 }}>
          <InputLabel id="assignToLabelselect">Executor</InputLabel>
          <Select
            size='small'
            name="assignTo"
            labelId="assignToLabelselect"
            id="assignTo-select"
            value={filters.assignTo}
            label="Executor"
            onChange={handleFilter}
          >
            <MenuItem value=''>Todos</MenuItem>
            {
              [...new Map(tasks.map(task => task.assignTo).map(item =>
                [item['_id'], item])).values()
              ]
                .map(task => (
                  <MenuItem value={task._id} key={`assignTo${task._id}`}>{task.name}</MenuItem>
                ))
            }
          </Select>
        </FormControl>
        <FormControl size='small' fullWidth sx={{ width: '8rem', minWidth: 100, marginRight: 1 }}>
          <InputLabel id="statusLabel-select">Status</InputLabel>
          <Select
            size='small'
            name="status"
            labelId="statusLabel-select"
            id="status-select"
            value={filters.status}
            label="Status"
            onChange={handleFilter}
          >
            <MenuItem value={null}>Todos</MenuItem>
            <MenuItem value={"open"}>Aberto</MenuItem>
            <MenuItem value={"closed"}>Fechado</MenuItem>
            <MenuItem value={"standBy"}>Stand-by</MenuItem>
            <MenuItem value={"revision"}>Revisão</MenuItem>
          </Select>
        </FormControl>
        <FormControl size='small' fullWidth sx={{ width: '8rem', minWidth: 150, marginRight: 1 }}>
          <InputLabel id="participantLabelselect">Participante</InputLabel>
          <Select
            size='small'
            name="participants"
            labelId="participantLabelselect"
            id="participant-select"
            value={filters.participant}
            label="Participante"
            onChange={handleFilter}
          >
            <MenuItem value=''>Todos</MenuItem>
            {taskUsers?.map(user => <MenuItem value={user._id} key={`participant${user._id}`}>{user.name}</MenuItem>)}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', flex: 1 }}></Box>
        <Button onClick={() => setFilters({ ...filtersInitialState, status: '' })}>Limpar Filtros</Button>
      </Box>
    </Paper>
  )
}