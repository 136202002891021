import { useState, useEffect, useContext } from "react";
import api from "../../../services/api";
import { useParams, useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Paper,
  SectionHeader,
  SectionContainer,
  ConfirmDialog,
  ErrorDialog,
  ChangePasswordDialog,
  DetailsRow,
  Box,
  Button,
  IconButton,
  Text,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CompanyListDialog,
  PermissionListDialog,
  FormGroup,
  FormControlLabel,
  Switch,
  Grid,
  Checkbox,
} from "../../W3libComponents";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export const UserDetails = ({ context }) => {
  const { id } = useParams();
  const { checkPermission } = useContext(AuthContext);
  const [user, setUser] = useState({
    companies: [],
    permissions: [],
  });
  const history = useHistory();
  const { setLoading } = context;
  const [showPermissionListDialog, setShowPermissionListDialog] =
    useState(false);
  const [showCompanyListDialog, setShowCompanyListDialog] = useState(false);
  const [activeConfirmDialog, setActiveConfirmDialog] = useState(false);
  const [showNewPasswordDialog, setShowNewPasswordDialog] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = () => {
    setLoading(true);
    api
      .get(`/user/${id}`)
      .then((response) => {
        setUser(response.data.user);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleError = (err) => {
    setError(err);
    setLoading(false);
  };

  const handleAddCompany = (ids) => {
    setShowCompanyListDialog(false);

    setLoading(true);

    api
      .patch(`/user/${id}`, {
        user: {
          ...user,
          companies: [
            ...user.companies,
            ...ids.map((item) => {
              return { company: item };
            }),
          ],
        },
      })
      .then((response) => {
        setUser(response.data.user);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const deleteEmployeerCompany = (idCompany) => {
    setLoading(true);

    api
      .patch(`/user/${id}`, {
        user: {
          ...user,
          companies: user.companies.filter(
            (item) => item.company.id !== idCompany
          ),
        },
      })
      .then((response) => {
        setUser(response.data.user);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleAddPermission = () => {
    loadUser();
    setShowPermissionListDialog(false);
  };

  const handleRemovePermission = (permission) => {
    setLoading(true);
    api
      .delete(`/user/${id}/permission/${permission}`)
      .then((response) => {
        loadUser();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleActive = () => {
    setActiveConfirmDialog(false);
    setLoading(true);
    api
      .patch(`/user/${id}`, {
        user: {
          active: !user.active,
        },
      })
      .then((response) => {
        setLoading(false);
        loadUser();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleGeneratePassword = () => {
    setLoading(true);

    api
      .post(`/user/${user._id}/generatepassword`)
      .then((response) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <SectionContainer>
        <SectionHeader
          title={user.name}
          editButton={checkPermission(["user-edit"]) === true ? true : false}
          editButtonAction={() => history.push(`/user/edit/${id}`)}
        />
        {checkPermission(["root"]) && (
          <Paper
            sx={{
              padding: 2,
              marginBottom: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text sx={{ ...styles.subtitle, flex: 1 }}>Acesso</Text>
            <Box sx={{ marginRight: 2 }}>
              <FormGroup>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      onClick={() => setActiveConfirmDialog(true)}
                      checked={user?.active}
                    />
                  }
                  label={user?.active ? "Ativo" : "Inativo"}
                />
              </FormGroup>
            </Box>
            <Box>
              <Button
                size="small"
                variant="outlined"
                onClick={handleGeneratePassword}
              >
                Gerar Nova Senha
              </Button>
            </Box>
          </Paper>
        )}
        <Paper>
          <DetailsRow label="E-mail" value={user.email} />
        </Paper>
        <Paper sx={styles.contentContainer}>
          <Box sx={styles.subtitleContainer}>
            <Text sx={styles.subtitle}>Empresas</Text>
            <Button
              onClick={() => setShowCompanyListDialog(true)}
              variant="outlined"
              size="small"
            >
              Adicionar
            </Button>
          </Box>
          <Table size="small">
            <TableBody>
              {user.companies.map((item, index) => (
                <CompanyRow
                  key={`companyrow${index}`}
                  company={item.company}
                  deleteEmployeerCompany={deleteEmployeerCompany}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Paper sx={styles.contentContainer}>
          <Box sx={styles.subtitleContainer}>
            <Text align="left" sx={styles.subtitle}>
              Permissões
            </Text>
            {checkPermission(["root"]) && (
              <Button
                onClick={() => setShowPermissionListDialog(true)}
                variant="outlined"
                size="small"
              >
                Adicionar
              </Button>
            )}
          </Box>

          <Table size="small">
            <TableBody>
              {user.permissions.map((item, index) => (
                <PermissionRow
                  key={`permissionrow${index}`}
                  permission={item}
                  handleRemovePermission={handleRemovePermission}
                  checkPermission={checkPermission}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      </SectionContainer>
      {showCompanyListDialog && (
        <CompanyListDialog
          handleClose={() => setShowCompanyListDialog(false)}
          handleSubmit={handleAddCompany}
        />
      )}
      {showPermissionListDialog && (
        <PermissionListDialog
          handleClose={() => setShowPermissionListDialog(false)}
          handleSubmit={handleAddPermission}
          userId={id}
        />
      )}

      {activeConfirmDialog && (
        <ConfirmDialog
          handleClose={() => setActiveConfirmDialog(false)}
          title="Alterar Status"
          question={`Tem certeza que deseja ${
            !user.active ? "Ativar" : "Desativar"
          } este usuário? ${
            user.active ? "Todo o acesso a plataforma será bloqueado." : ""
          }`}
          handleConfirm={() => handleActive()}
        />
      )}
      {error && (
        <ErrorDialog
          msg={error}
          title="w3lib Admin"
          handleClose={() => setError(null)}
        />
      )}
    </>
  );
};

const CompanyRow = ({ company, deleteEmployeerCompany }) => {
  const { name, _id } = company;
  const history = useHistory();

  return (
    <TableRow hover>
      <TableCell align="left">{name}</TableCell>
      <TableCell align="center" sx={{ width: 25 }}>
        <IconButton onClick={() => history.push(`/company/${_id}`)}>
          <VisibilityIcon sx={{ color: "#afafaf" }} />
        </IconButton>
      </TableCell>
      <TableCell align="right" sx={{ width: 25 }}>
        <IconButton onClick={() => deleteEmployeerCompany(_id)}>
          <HighlightOffIcon sx={{ color: "#afafaf" }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const PermissionRow = ({
  permission,
  handleRemovePermission,
  checkPermission,
}) => {
  const [showDeletePermissionDialog, setShowDeletePermissionDialog] =
    useState(false);

  return (
    <>
      <TableRow hover>
        <TableCell align="left">{permission.description}</TableCell>
        <TableCell align="right">
          {checkPermission(["root"]) && (
            <IconButton onClick={() => setShowDeletePermissionDialog(true)}>
              <HighlightOffIcon sx={{ color: "#afafaf" }} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      {showDeletePermissionDialog && (
        <ConfirmDialog
          handleClose={() => setShowDeletePermissionDialog(false)}
          title="Remoção de Permissão"
          question={`Deseja remover a permissão "${permission.description}" deste usuário?`}
          handleConfirm={() => {
            setShowDeletePermissionDialog(false);
            handleRemovePermission(permission.id);
          }}
        />
      )}
    </>
  );
};

const styles = {
  contentContainer: {
    padding: 2,
    marginTop: 2,
  },
  subtitle: {
    fontWeight: "600",
    flex: 1,
    fontSize: 16,
  },
  subtitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContect: "center",
    marginBottom: 3,
  },
};
