import { TASK_PRIORITIES, TASK_STATUS, pluralize } from ".";

export const priorityArrowAnimation = (query) => {
  if (query.style["-webkit-transform"].includes("180")) {
    query.style.transform = "rotate(90deg)";
    return;
  }
  query.style.transform = "rotate(180deg)";
  query.style.transition = "transform 0.2s ease";
};

export const statusLabelData = (currentStatus) =>
  TASK_PRIORITIES[currentStatus] || TASK_STATUS[currentStatus];

const getLastInteractionDate = (item) => {
  const interactions = item.interactions;

  if (interactions && interactions.length > 0) {
    return interactions[interactions.length - 1].createdAt;
  }
  return item.createdAt;
};

export const tasksOrderBy = (newTasks, proprietyOrderBy, nameOrderBy) => {
  if (proprietyOrderBy) {
    newTasks = newTasks.sort((a, b) => {
      const constA =
        nameOrderBy === "lastInteraction"
          ? getLastInteractionDate(a)
          : a[nameOrderBy];
      const constB =
        nameOrderBy === "lastInteraction"
          ? getLastInteractionDate(b)
          : b[nameOrderBy];
      if (proprietyOrderBy[nameOrderBy] === "asc") {
        return constA > constB ? 1 : constA < constB ? -1 : 0;
      }
      return constA > constB ? -1 : constA < constB ? 1 : 0;
    });
  }
};

export const calcDurationInHours = (duration) => {
  if (!duration) return;

  let readableDuration = "";

  const hours = Math.floor(duration / 60);
  const minutes = Math.floor(duration % 60);

  if (Boolean(hours))
    readableDuration += `${hours} ${pluralize("hora", hours)}`;

  if (Boolean(hours) && Boolean(minutes)) readableDuration += ` e `;

  if (Boolean(minutes))
    readableDuration += `${minutes} ${pluralize("minuto", minutes)}`;

  return readableDuration;
};
