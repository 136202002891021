import { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";
import { AuthContext } from "../../../contexts/AuthContext";
import api from "../../../services/api";
import {
  Box,
  Button,
  Div,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  SectionContainer,
  SectionHeader,
  Select,
  Text,
  TextField,
} from "../../W3libComponents";

export const TaskNew = () => {
  const { setLoading } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const [task, setTask] = useState({
    type: "task",
    title: "",
    description: "",
    documents: [],
    assignTo: "",
  });

  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [errors, setErrors] = useState({});
  const [checkErrors, setCheckErrors] = useState(false);

  const onDrop = useCallback((files) => {
    const formData = new FormData();
    formData.append("file", files[0], files[0].name);

    setLoading(true);

    api
      .post(`/document`, formData)
      .then((response) => {
        setDocuments((documents) => [...documents, response.data.upload]);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 10,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTask({
      ...task,
      [name]: value,
    });
  };

  useEffect(() => {
    setLoading(true);
    api.get(`/user/${user._id}/companies`).then((response) => {
      const { companies } = response.data.user;
      setLoading(false);
      setCompanies(companies);
    });

    api.get("/user").then((response) => {
      const { users } = response.data;
      const activeUsers = users.filter((user) => user.active);
      setLoading(false);
      setUsers(activeUsers);
    });
  }, []);

  const handleSave = () => {
    setCheckErrors(true);

    if (!validateFields()) return false;

    setLoading(true);
    api
      .post(`/task`, { ...task, documents: documents.map((item) => item._id) })
      .then((response) => {
        setLoading(false);
        history.push(`/task/${response.data.taskId}`);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  };

  useEffect(() => {
    if (checkErrors) validateFields();
  }, [task]);

  const validateFields = () => {
    const { title, description, company, assignTo } = task;

    let newErrors = {};

    if ((title === "") | !title)
      newErrors["title"] = { error: "O campo título é obrigatório" };

    if (title.length < 4)
      newErrors["title"] = {
        error: "O campo título deve conter no mínimo 4 caracteres",
      };

    if ((description === "") | !description)
      newErrors["description"] = { error: "O campo descrição é obrigatório" };

    if (description.length < 10)
      newErrors["description"] = {
        error: "O campo descrição deve conter no mínimo 10 caracteres",
      };

    if ((company === "") | !company)
      newErrors["company"] = { error: "O campo cliente é obrigatório" };

    if ((assignTo === "") | !assignTo)
      newErrors["assignTo"] = { error: "O campo responsável é obrigatório" };

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return false;
    }

    return true;
  };

  return (
    <SectionContainer>
      <SectionHeader
        title={task?.title || "Nova Tarefa"}
        style={{ color: "#8862D4" }}
      />
      <Div style={{ width: "100%" }}>
        <Paper
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: 20,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="type-select">Tipo</InputLabel>
                    <Select
                      name="type"
                      labelId="type-select"
                      id="type-select"
                      value={task.type}
                      label="Tipo"
                      onChange={handleChange}
                    >
                      <MenuItem value={"task"}>Solicitação</MenuItem>
                      <MenuItem value={"error"}>Erro</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="status-select">Status</InputLabel>
                    <Select
                      name="status"
                      labelId="status-select"
                      id="status-select"
                      value={task.status}
                      label="Status"
                      onChange={handleChange}
                      defaultValue="open"
                    >
                      <MenuItem value={"open"}>Aberta</MenuItem>
                      <MenuItem value={"standBy"}>Stand By</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="priority-select">Prioridade</InputLabel>
                    <Select
                      name="priority"
                      labelId="priority-select"
                      id="priority-select"
                      value={task.priority}
                      label="Prioridade"
                      onChange={handleChange}
                      defaultValue="2"
                    >
                      <MenuItem value={"4"}>Urgência</MenuItem>
                      <MenuItem value={"3"}>Alta</MenuItem>
                      <MenuItem value={"2"}>Normal</MenuItem>
                      <MenuItem value={"1"}>Baixa</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel required id="company">
                      Cliente
                    </InputLabel>
                    <Select
                      name="company"
                      labelId="company"
                      id="company"
                      label="Cliente"
                      onChange={handleChange}
                      error={errors?.company}
                      helperText={errors?.company?.error}
                    >
                      {companies.map((company, index) => (
                        <MenuItem
                          key={`company${index}`}
                          value={company.company._id}
                        >
                          {company.company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel required id="company">
                      Responsável
                    </InputLabel>
                    <Select
                      name="assignTo"
                      labelId="assignTo"
                      id="assignTo"
                      label="Responsável"
                      onChange={handleChange}
                      error={errors?.assignTo}
                      helperText={errors?.assignTo?.error}
                    >
                      {users
                        .filter((user) => user.active)
                        .map(({ name, _id }, index) => (
                          <MenuItem key={`assignTo${index}`} value={_id}>
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Título"
                    value={task.title}
                    name="title"
                    fullWidth
                    required
                    error={errors?.title}
                    helperText={errors?.title?.error}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    minRows={5}
                    maxRows={10}
                    label="Descrição"
                    value={task?.description}
                    multiline
                    name="description"
                    required
                    error={errors?.description}
                    helperText={errors?.description?.error}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Box sx={styles.filesContainer}>
                {documents.map((item, index) => (
                  <a target="_blank" href={item.url}>
                    <Text sx={{ fontSize: 14, padding: 1 }}>{item.name}</Text>
                  </a>
                ))}
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box
                sx={{
                  ...styles.uploadContainer,
                  backgroundColor: isDragActive ? "#547aa5" : "#FFF",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Text>
                  Arraste e solte seus arquivos aqui ou clique para selecionar
                </Text>
              </Box>
            </Grid>
            <Grid
              xs={12}
              sx={{
                paddingTop: 2,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={handleSave}
                style={{ width: 250, height: 45 }}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Div>
    </SectionContainer>
  );
};

const styles = {
  uploadContainer: {
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: "#afafaf",
    borderRadius: 2,
    padding: 2,
    marginLeft: 2,
    height: 100,
    color: "#afafaf",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filesContainer: {
    padding: 1,
  },
};
