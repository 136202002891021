import { useState, useEffect } from 'react'
import api from '../../../services/api';
import { FormControl, InputLabel, Select, MenuItem } from '../../W3libComponents'

export const UserSelect = (props) => {

    const {
        handleSelect,
        label = 'Contatos',
        name = 'user'
    } = props

    const [users, setUsers] = useState([]);

    useEffect(() => {

        api.get('/user').then(response => {
            const { users } = response.data
            setUsers(users)
        })

    }, [])

    return (
        <FormControl fullWidth >
            <InputLabel required id={name}>{label}</InputLabel>
            <Select
                name={name}
                labelId={name}
                id={name}
                label={label}
                onChange={handleSelect}
            >
                {users.map(({ name, _id }, index) => (
                    <MenuItem key={`user${index}`} value={_id}>{name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}