import { useState, useEffect, useContext } from 'react'
import { AppContext } from "../../../contexts";
import api from '../../../services/api'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, Text, TableRow, TableCell, Box, UserNewDialog, TextField } from '../../W3libComponents'

export const AssignToDialog = (props) => {

    const { setLoading } = useContext(AppContext);
    const [users, setUsers] = useState([])
    const [filter, setFilter] = useState('')
    const [interaction, setInteraction] = useState('')
    const [assignTo, setAssignTo] = useState([])
    const [indexMouseOver, setIndexMouseOver] = useState(null)
    const [showUserNewDialog, setShowUserNewDialog] = useState(false)

    const {
        title = 'Selecione o Contato',
        cancelButton = true,
        handleClose,
        handleSubmit,
        newContactButton = true
    } = props

    useEffect(() => {
        loadUsers()
    }, [])

    const loadUsers = () => {
        setLoading(true)

        api.get('/user').then(response => {
            setLoading(false)
            setUsers(response.data.users)
        }).catch(err => {
            setLoading(false)
            console.log(err);
        })
    }

    const handleAfterSave = () => {
        setShowUserNewDialog(false)
        loadUsers()
    }

    const handleChangeFilter = ({target})=> {
        const { value } = target;
        setFilter(value)
    }

    const handleChangeInteraction = ({target})=> {
      const { value } = target;
      setInteraction(value)
  }

    return (
        <>
            <Dialog open={true} maxWidth='md' fullWidth sx={{ marginTop: 6 }} >
                <DialogTitle>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ flex: 1 }}>{title}</Box>
                        <Box>{newContactButton && <Button size='small' onClick={() => setShowUserNewDialog(true)}>Novo Contato</Button>}</Box>
                    </Box>
                    <Box sx={{ display: 'flex'}}>
                        <TextField
                            variant="outlined"
                            refs="filter"
                            name="filter"
                            margin="dense"
                            placeholder='Buscar'
                            fullWidth
                            autoFocus
                            onChange={handleChangeFilter}
                        />
                    </Box>

                </DialogTitle>
                <DialogContent>
                    {users.filter(item=>item.name?.toLowerCase().includes(filter.toLowerCase())).map((user, index) => (
                        <Table size='small'>
                            <TableBody>
                                <TableRow hover>
                                    <TableCell
                                        onMouseOver={() => setIndexMouseOver(index)}
                                    >
                                        {user.name}
                                    </TableCell>
                                    <TableCell
                                        style={{color: 'red'}}
                                        onMouseOver={() => setIndexMouseOver(index)}
                                        sx={{ width: 100, height: 28.75 }}>
                                        {indexMouseOver === index &&
                                            <Button
                                                sx={{ padding: '3px', paddingLeft: '10px', paddingRight: '10px' }}
                                                size='small'
                                                variant='contained'
                                                onClick={() => setAssignTo([user])}>
                                                Selecionar
                                            </Button>
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    ))}
                      
                    <Box sx={{ display: 'flex', marginTop: 2 }}>
                    <Text style={{fontSize: 20 }}>Digite sua interaçao</Text>
                    </Box>
                    <Box sx={{ display: 'flex'}}>
                    <TextField
										name='interaction'
										label="Digite sua interação ou arraste um arquivo aqui"
										// value={newInteraction?.interaction}
										minRows={5}
										maxRows={10}
										multiline
										required
										// error={error}
										// helperText={error ? 'O campo de interação deve contar no mínimo 2 caracteres' : ''}
										onChange={handleChangeInteraction}
									/>
                    </Box>
									
                </DialogContent>
                <DialogActions>
                    {cancelButton && <Button onClick={handleClose}>Cancelar</Button>}
                    <Button onClick={() => handleSubmit(assignTo, interaction)}>Confirmar</Button>
                </DialogActions>
            </Dialog>
            {showUserNewDialog &&
                <UserNewDialog
                    handleAfterSave={handleAfterSave}
                    handleClose={() => setShowUserNewDialog(false)} />
            }
        </>
    )
}