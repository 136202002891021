import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { IconButton } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import XLSX from "xlsx";
import { AppContext } from "../../../contexts/AppContext";
import { AuthContext } from "../../../contexts/AuthContext";
import api from "../../../services/api";
import {
  Badge,
  Box,
  Text as CustomText,
  Div,
  Paper,
  SectionContainer,
  SectionHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TaskFilters,
} from "../../W3libComponents";
import { Button } from "../../atoms/index";
import { StatusLabel } from "./StatusLabel";
import { TASK_STATUS } from "../../../utils";

export const TaskList = () => {
  const history = useHistory();
  const { setLoading } = useContext(AppContext);
  const [tasks, setTasks] = useState([]);
  const [taskUsers, setTaskUsers] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [orderBy, setOrderBy] = useState({
    createdAt: "desc",
    lastInteraction: "desc",
    priority: "desc",
  });
  // const [createdAtOrder, setCreatedAtOrder] = useState('desc');
  // const [lastInteractionOrder, setLastInteractionOrder] = useState('desc');

  const loadTasks = async () => {
    setLoading(true);
    try {
      const taskResponse = await api.get(`/task`);
      const userResponse = await api.get(`/user`);
      setTasks(taskResponse.data.tasks);
      setTaskUsers(userResponse.data.users);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportToExcel = () => {
    const data = filteredTasks.map((task) => [
      task.number,
      task.priority,
      task.title,
      task.createdBy.name,
      task.company?.name,
      task.assignTo.name,
      task.status,
      task.createdAt,
    ]);
    const worksheet = XLSX.utils.aoa_to_sheet([
      [
        "ID",
        "Prioridade",
        "Título",
        "Autor",
        "Cliente",
        "Executor",
        "Status",
        "Data de Criação",
      ],
      ...data,
    ]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tarefas");
    XLSX.writeFile(workbook, "tarefas.xlsx");
  };

  useEffect(() => {
    loadTasks();

    if (window.localStorage.getItem("w3lib-admin-orderBy")) {
      const localOrderBy = JSON.parse(
        window.localStorage.getItem("w3lib-admin-orderBy")
      );
      setOrderBy(localOrderBy);
    }
  }, []);

  const handleOrderBy = ({ name, value }) => {
    setOrderBy({
      // ...orderBy,
      [name]: value,
    });

    window.localStorage.setItem(
      "w3lib-admin-orderBy",
      JSON.stringify({
        ...orderBy,
        [name]: value,
      })
    );
  };

  // const handleCreatedAtOrder = () => {
  //   setCreatedAtOrder(createdAtOrder == 'desc' ? 'asc' : 'desc')
  //   window.localStorage.setItem('w3lib-admin-createdAtOrder', JSON.stringify(createdAtOrder == 'desc' ? 'asc' : 'desc'));
  // }

  // const handleLastInteractionOrder = () => {
  //   setLastInteractionOrder(lastInteractionOrder == 'desc' ? 'asc' : 'desc')
  //   window.localStorage.setItem('w3lib-admin-lastInteractionOrder', JSON.stringify(lastInteractionOrder == 'desc' ? 'asc' : 'desc'));
  // }

  return (
    <SectionContainer>
      <Div style={{ display: "flex", flexDirection: "row", gap: 14 }}>
        <SectionHeader
          title="Tarefas"
          newButtonAction={() => history.push(`/task/new`)}
          newButton
        />
        <Button
          variant="contained"
          size="small"
          style={{ height: 26, marginTop: 8, width: 250 }}
          onClick={handleExportToExcel}
        >
          <Text style={{ fontSize: 13 }}> Exportar para Excel</Text>
        </Button>
      </Div>
      <>
        <TaskFilters
          tasks={tasks}
          setFilteredTasks={setFilteredTasks}
          filteredTasks={filteredTasks}
          taskUsers={taskUsers}
          orderBy={orderBy}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  {`#`}
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Prioridade
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleOrderBy({
                        name: "priority",
                        value: orderBy.priority == "asc" ? "desc" : "asc",
                      })
                    }
                  >
                    {orderBy.priority == "desc" ? (
                      <ArrowDownwardIcon sx={{ width: 16 }} />
                    ) : (
                      <ArrowUpwardIcon sx={{ width: 16 }} />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  Titulo
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Autor
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Cliente
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Executor
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", width: 120 }}
                >
                  Cadastro
                  <IconButton
                    onClick={() =>
                      handleOrderBy({
                        name: "createdAt",
                        value: orderBy.createdAt == "asc" ? "desc" : "asc",
                      })
                    }
                  >
                    {orderBy.createdAt == "desc" ? (
                      <ArrowDownwardIcon sx={{ width: 16 }} />
                    ) : (
                      <ArrowUpwardIcon sx={{ width: 16 }} />
                    )}
                  </IconButton>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", width: 120 }}
                >
                  Atualização
                  <IconButton
                    onClick={() =>
                      handleOrderBy({
                        name: "lastInteraction",
                        value:
                          orderBy.lastInteraction == "asc" ? "desc" : "asc",
                      })
                    }
                  >
                    {orderBy.lastInteraction == "desc" ? (
                      <ArrowDownwardIcon sx={{ width: 16 }} />
                    ) : (
                      <ArrowUpwardIcon sx={{ width: 16 }} />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTasks.map((task, index) => (
                <TaskListRow
                  key={`tasklistrow-${task._id}-${index}`}
                  task={task}
                  index={index}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </SectionContainer>
  );
};

const TaskListRow = ({ task }) => {
  const { user } = useContext(AuthContext);
  const notViewedinteractions = task.interactions.filter((item) => {
    return !item.viewedBy.includes(user._id);
  }).length;

  return (
    <TableRow
      hover
      sx={{ cursor: "pointer", textDecoration: "none" }}
      component={Link}
      to={`/task/${task._id}`}
      target="_blank"
      rel="noopener"
    >
      <TableCell align="center">
        <Text>{task?.number}</Text>
      </TableCell>
      <TableCell align="center">
        <StatusLabel currentStatus={task?.priority} />
      </TableCell>
      <TableCell align="left" aria-label="Titulo">
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Text>{task?.title}</Text>
          {!task.viewedBy.includes(user._id) && (
            <Badge color="primary" badgeContent={"Nova"} />
          )}
          {notViewedinteractions > 0 && task.viewedBy.includes(user._id) && (
            <Badge color="secondary" badgeContent={notViewedinteractions} />
          )}
        </Box>
      </TableCell>
      <TableCell align="center" aria-label="Criador">
        <Text>{task?.createdBy.name}</Text>
      </TableCell>
      <TableCell align="center">
        <Text>{task.company?.name}</Text>
      </TableCell>
      <TableCell align="center" aria-label="Destinatário">
        <Box>
          <Text>
            {!task?.assignTo && "Não especificado"}
            {task?.assignTo && task.assignTo.name}
          </Text>
          <Text
            sx={{
              color: "gray",
              fontSize: 12,
              cursor: "pointer",
              "&:hover": {
                color: "#093261",
                "& .Participants": {
                  display: "block",
                },
              },
              "& .Participants": {
                display: "none",
              },
            }}
          >
            {task?.participants.length > 0
              ? `+${task?.participants.length} Participantes`
              : ""}
            <Box className="Participants">
              <Paper
                sx={{
                  textAlign: "left",
                  padding: 1,
                  marginLeft: 8,
                  color: "gray",
                  position: "absolute",
                  minWidth: 200,
                  minHeight: 60,
                }}
              >
                <Text
                  sx={{ fontWeight: "bold", fontSize: 14, marginBottom: 1 }}
                >
                  Participantes
                </Text>
                {task.participants.map((participant) => (
                  <Text key={participant._id} sx={{ fontSize: 12 }}>
                    {participant.name}
                  </Text>
                ))}
              </Paper>
            </Box>
          </Text>
        </Box>
      </TableCell>
      <TableCell align="center" aria-label="Status">
        <Text>{TASK_STATUS[task?.status]?.text}</Text>
      </TableCell>
      <TableCell align="center" aria-label="Data de Criação">
        <Text>{moment(task?.createdAt).format("DD/MM/YY")}</Text>
      </TableCell>
      <TableCell align="center" aria-label="Última Interação">
        {task.interactions.length > 0 && (
          <Text>
            {moment(
              task?.interactions[task?.interactions?.length - 1].createdAt
            ).format("DD/MM/YY")}
          </Text>
        )}
        {task.interactions.length == 0 && (
          <Text>{moment(task?.createdAt).format("DD/MM/YY")}</Text>
        )}
      </TableCell>
    </TableRow>
  );
};

const Text = (props) => (
  <CustomText {...props} sx={{ fontSize: "15px", ...props.sx }} />
);
