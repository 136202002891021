import api from "../../../services/api";
import { useState, useEffect, useContext } from "react";
import { Div, Text, Button, AssignToDialog, transformDate } from "../../W3libComponents";
import { AppContext } from "../../../contexts/AppContext";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AttachmentIcon from "@mui/icons-material/Attachment";

export const SideMenu = ({
  task,
  newTask,
  taskId,
  setTask,
  handleSubmit,
  handleSave,
  setEdit,
  edit,
}) => {
  const { assignTo, createdBy, createdAt, closedAt } = task;
  const [showDialog, setShowDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const { setLoading } = useContext(AppContext);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/user`)
      .then((response) => {
        setUsers(response.data.users);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    <Div
      style={{
        width: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Div
        style={{
          paddingBottom: 10,
          marginBottom: 10,
          marginTop: 10,
          borderBottom: "1px solid #547AA5",
          borderBottomLength: 5,
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          disabled={!edit}
          sx={{
            justifyContent: "space-between",
            padding: 0,
            textTransform: "inherit",
            transition: "none",
            "&:hover": {
              backgroundColor: "primary",
              color: "#093261",
              "& .Text": {
                color: "#093261",
              },
            },
          }}
          size="small"
          variant="text"
          onClick={() => setShowDialog(true)}
          endIcon={<AssignmentIndIcon />}
        >
          <Text
            className="Text"
            color="primary"
            sx={{
              fontWeight: "bold",
            }}
          >
            Destinatário
          </Text>
        </Button>
        <AssignToDialog
          setTask={setTask}
          taskId={taskId}
          task={task}
          data={users}
          open={showDialog}
          handleClose={() => setShowDialog(false)}
        />
        <Text style={{ fontSize: 15, justifyContent: "space-around" }}>
          {!assignTo ? "Atribuir" : assignTo.name}
        </Text>
      </Div>
      <Div
        style={{
          paddingBottom: 10,
          marginBottom: 10,
          borderBottom: "1px solid #547AA5",
          borderBottomLength: 5,
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label htmlFor={edit ? "add-file" : null}>
          <Button
            disabled={!edit}
            sx={{
              padding: 0,
              width: "100%",
              justifyContent: "space-between",
              textTransform: "inherit",
              transition: "none",
              "&:hover": {
                backgroundColor: "primary",
                color: "#093261",
                "& .Text": {
                  color: "#093261",
                },
              },
            }}
            size="small"
            variant="text"
            type="button"
            component="span"
            endIcon={<AttachmentIcon />}
          >
            <Text
              disabled={!edit}
              className="Text"
              color="primary"
              sx={{ fontWeight: "bold" }}
            >
              Anexos
            </Text>
          </Button>
        </label>
        <input
          type="file"
          name="file"
          id="add-file"
          onChange={handleSubmit}
          hidden
        />
        {task.documents.length > 0 && (
          <>
            {task.documents.map((document) => (
              <a
                href={document.url}
                download
                style={{
                  maxWidth: "15rem",
                  marginRight: "1rem",
                  marginTop: "0.5rem",
                  textDecoration: "none",
                  wordWrap: "break-word"
                }}
              >
                <Text style={{ fontSize: 12 }}>{document.name}</Text>
              </a>
            ))}
          </>
        )}
        {task.documents.length <= 0 && (
          <Text style={{ fontSize: 15 }}>Adicionar</Text>
        )}
      </Div>
      <Div
        style={{
          paddingBottom: 10,
          marginBottom: 10,
          borderBottom: "1px solid #547AA5",
          borderBottomLength: 5,
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          disabled
          sx={{
            cursor: "default",
            justifyContent: "space-between",
            padding: 0,
            textTransform: "inherit",
            transition: "none",
            "&:hover": {
              backgroundColor: "primary",
              color: "#093261",
              "& .Text": {
                color: "#093261",
              },
            },
          }}
          size="small"
          variant="text"
          // onClick={() => console.log("autor")}
          endIcon={<AccountCircleIcon />}
        >
          <Text className="Text" color="primary" sx={{ fontWeight: "bold" }}>
            Autor
          </Text>
        </Button>
        <Text sx={{ fontSize: 15 }}>{createdBy?.name}</Text>
      </Div>
      {!newTask && (
        <Div
          style={{
            paddingBottom: 10,
            marginBottom: 10,
            borderBottom: "1px solid #547AA5",
            borderBottomLength: 5,
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text color="primary" sx={{ fontWeight: "bold" }}>
            Criado em
          </Text>
          <Text sx={{ fontSize: 15 }}>{transformDate(createdAt)}</Text>
        </Div>
      )}

      <Div
        style={{
          paddingBottom: 10,
          marginBottom: 10,
          borderBottom: "1px solid #547AA5",
          borderBottomLength: 5,
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {task.status !== "open" && (
          <>
            <Text color="primary" sx={{ fontWeight: "bold" }}>
              Finalizado em
            </Text>
            <Text sx={{ fontSize: 15 }}>{closedAt ? transformDate(closedAt) : "Agora."}</Text>{" "}
          </>
        )}
        {task.status === "open" && (
          <Text color="primary" sx={{ fontWeight: "bold" }}>
            Tarefa em andamento.
          </Text>
        )}
      </Div>

      <Div
        style={{
          paddingBottom: 10,
          marginBottom: 10,
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        {!newTask && (
          <Button
            style={{ width: "0.5rem", marginRight: "0.5rem" }}
            variant="contained"
            size="small"
            color="primary"
            onClick={setEdit}
          >
            Editar
          </Button>
        )}
        {edit || newTask ? (
          <Button
            style={{ width: "0.5rem" }}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
          >
            Salvar
          </Button>
        ) : null}
      </Div>
    </Div>
  );
};
