import api from "../../../services/api";
import { useState, useContext } from "react";
import { AppContext } from '../../../contexts/AppContext'
import { Grid, TextField, Button, Div, Text } from '../../W3libComponents'

export const CompanyForm = (props) => {

    const { handleAfterSave = () => { } } = props

    const { setLoading } = useContext(AppContext);
    const [company, setCompany] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCompany({
            ...company,
            [name]: value,
        });
    };

    const handleSave = () => {

        setLoading(true)
        api.post(`/company`, { company, }).then((res) => {
            setLoading(false)
            handleAfterSave()
        }).catch((err) => {
            setLoading(false)
            console.log(err.response.data);
        });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        label="Nome da Empresa"
                        refs="name"
                        name="name"
                        margin="dense"
                        fullWidth
                        autoFocus
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        label="Webpage"
                        refs="urlCompanyPage"
                        name="urlCompanyPage"
                        margin="dense"
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Div style={{ justifyContent: "flex-end" }}>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        style={{ bottom: 0 }}
                        onClick={handleSave}
                    >
                        <Text style={{ fontSize: 12 }}>Cadastrar</Text>
                    </Button>
                </Div>
            </Grid>
        </Grid>
    )
}