import api from "../../../services/api";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from 'moment'
import { AppContext } from "../../../contexts/AppContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { Grid, Text, Paper, Box, Button, SectionHeader, SectionContainer, TextField, AddHourDialog, UploadBox } from "../../W3libComponents";
import { getStatus, getTaskType } from "../../../utils";

export const ProjectDetails = () => {
    const { setLoading } = useContext(AppContext);
    const { user } = useContext(AuthContext);
    const { id } = useParams();

    const [project, setProject] = useState({
        documents: [],
        interactions: [],
    });
    const [newInteraction, setNewInteraction] = useState({ interaction: '', documents: [] });
    const [error, setError] = useState(false)
    const [showAddHourDialog, setShowAddHourDialog] = useState(false)

    useEffect(() => {
        loadProject()
    }, []);

    const loadProject = () => {
        setLoading(true);
        api.get(`/project/${id}`).then((response) => {
            setProject(response.data.project);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        });
    }

    const handleUpload = ({ documentId }) => {
        let newDocuments = newInteraction.documents
        newDocuments.push(documentId)

        setNewInteraction({ ...newInteraction, documents: newDocuments })
    }

    const handleChangeStatus = () => {

        // setLoading(true);

        // let interaction = `Projeto ${project.status == 'opened' ? 'finalizado' : 'reaberto'} por ${user.name} em ${moment().format("DD/MM/YYYY hh:mm")}`

        // api.patch(`/task/${id}`, {
        //     status: task.status == 'open' ? 'closed' : 'open',
        //     closedAt: moment(),
        // }).then(response => {
        //     setLoading(false)
        //     saveInteraction({ msg: { interaction } })
        // }).catch(err => {
        //     setLoading(false)
        // });
    }

    const saveInteraction = ({ msg = null }) => {

        if (newInteraction.interaction.length < 2 && !msg) {
            setError(true)
            return false
        }

        setError(false)
        setLoading(true);

        api.post(`/project/${id}/interaction`, msg ? { interaction: msg.interaction } : newInteraction).then(response => {
            setLoading(false);
            loadProject()
            setNewInteraction({ interaction: '', documents: [] })
        }).catch(err => {
            console.log('erro')
            setLoading(false);
        })
    }

    const handleChange = ({ target }) => {
        const { value } = target;
        setNewInteraction({
            ...newInteraction,
            interaction: value,
        });
    };

    return (
        <SectionContainer>
            <SectionHeader title={`Projeto - ${project.title}`} />
            <Paper sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Box sx={{ paddingBottom: 2 }}>
                            <Text sx={{ fontSize: 14 }}>{project.description}</Text>
                        </Box>

                        <Text sx={{ fontSize: 16, fontWeight: 700, paddingBottom: 1, marginTop: 2, color: '#547aa5' }}>Interações</Text>
                        {project.interactions.map((item, index) => (
                            <Box key={`projectinteraction${item._id}`} sx={{
                                borderColor: 'primary.main',
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderRadius: 1,
                                marginBottom: 1
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    backgroundColor: '#e6f3ff',
                                    padding: 2,
                                    borderRadius: 1
                                }}>
                                    <Text sx={{ fontSize: 14 }}>Por {item.createdBy.name} em {moment(item.createdAt).format('lll')}</Text>
                                </Box>
                                <Text sx={{ padding: 2, fontSize: 14 }}>{item.interaction}</Text>
                                {item.documents.map((document, index) => (
                                    <Text key={`interactiondocument${index}`} sx={{ fontSize: 12, paddingLeft: 2, paddingBottom: 2 }}><a target='_blank' href={document.url}>{document.name}</a></Text>
                                ))}
                            </Box>
                        ))}
                        {project.status == 'opened' &&
                            <>
                                <UploadBox handleUpload={handleUpload} >
                                    <TextField
                                        name='interaction'
                                        label="Digite sua interação ou arraste um arquivo aqui"
                                        value={newInteraction?.interaction}
                                        minRows={5}
                                        maxRows={10}
                                        multiline
                                        required
                                        error={error}
                                        helperText={error ? 'O campo de interação deve contar no mínimo 2 caracteres' : ''}
                                        onChange={handleChange}
                                    />
                                </UploadBox >
                                <Box>
                                    {newInteraction.documents.map((item, index) => (
                                        <Text key={`interactionnewdocument${index}`} sx={{ fontSize: 12 }}><a target='_blank' href={'#'}>Arquivo {index + 1}</a></Text>
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant='outlined' onClick={saveInteraction}>Adicionar Interação</Button>
                                </Box>
                            </>
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            <Text sx={{ fontSize: 12 }}>Aberto por:</Text>
                            <Text sx={{ fontSize: 14, marginBottom: 2, fontWeight: 700 }}>{project?.createdBy?.name}</Text>
                            <Text sx={{ fontSize: 12 }}>Data de Criação</Text>
                            <Text sx={{ fontSize: 14, marginBottom: 2, fontWeight: 700 }}>{moment(project?.createdAt).format("DD/MM/YYYY hh:mm")}</Text>
                            <Text sx={{ fontSize: 12 }}>Status</Text>
                            <Text sx={{ fontSize: 14, marginBottom: 2, fontWeight: 700 }}>{getStatus(project?.status)}</Text>
                            <Text sx={{ fontSize: 12 }}>Tipo de Tarefa</Text>
                            <Text sx={{ fontSize: 14, marginBottom: 2, fontWeight: 700 }}>{getTaskType(project?.type)}</Text>
                            {project.minutes > 0 &&
                                <>
                                    <Text sx={{ fontSize: 12 }}>Tempo de Atendimento</Text>
                                    <Text sx={{ fontSize: 14, marginBottom: 2, fontWeight: 700 }}>{moment().startOf('day').add(project?.minutes, 'minutes').format('H:mm')}</Text>
                                </>
                            }
                            {project.documents.legnth > 0 && <>
                                <Text sx={{ fontSize: 12 }}>Anexos</Text>
                                {project.documents.map((document, index) => (
                                    <Text key={`projectdocument${index}`} sx={{ fontSize: 12 }}><a target='_blank' href={document.url}>{document.name}</a></Text>
                                ))}
                            </>}
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                {project.status == 'opened' &&
                                    <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-start' }}>
                                        <Button variant='contained' onClick={handleChangeStatus}>Finalizar Tarefa</Button>
                                    </Box>
                                }
                                {project.status == 'closed' &&
                                    <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-start' }}>
                                        <Button variant='contained' onClick={handleChangeStatus}>Reabrir Tarefa</Button>
                                    </Box>
                                }
                                {project.status == 'opened' &&
                                    <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-start' }}>
                                        <Button variant='outlined' onClick={() => setShowAddHourDialog(true)}>Registrar Tempo</Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
            {showAddHourDialog && <AddHourDialog handleClose={() => setShowAddHourDialog(false)} handleConfirm={(minutes) => {
                saveInteraction({
                    msg: {
                        interaction: `${user.name} registrou ${moment().startOf('day').add(minutes, 'minutes').format('H:mm')} horas de atendimento deste chamado`
                    }
                })
                setShowAddHourDialog(false)
                loadProject()
            }} taskId={id} />}
        </SectionContainer>
    );
};