import { Dialog, DialogTitle, DialogContent, DialogActions, UserForm, Button } from '../../W3libComponents'

export const UserNewDialog = (props) => {

    const {
        title = 'Cadastro de novo Contato',
        handleClose,
        handleAfterSave
    } = props

    return (
        <Dialog open={true}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <UserForm handleAfterSave={handleAfterSave}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
            </DialogActions>
        </Dialog>)
}