export const Div = (props) => {
    const { style } = props

    return (
        <div {...props} style={{ ...styles.container, ...style }}></div>
    )
}
const styles = {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
    }
}