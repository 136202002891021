import { Button, Text, Box } from '../../atoms/index'

export const SectionHeader = (props) => {

    const {
        title = '',
        deleteButton = false,
        editButton = false,
        newButton = false,
        saveButton = false,
        style,
        number = '',
        editButtonAction = () => { },
        deleteButtonAction = () => { },
        newButtonAction = () => { },
        saveButtonAction = () => { }
    } = props

    return (
        <div style={{ ...{ display: 'flex', flexDirection: 'row', flex: 1, marginBottom: 15 }, ...style }}>
            <Box xs={6} sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                <Text variant='h4' weight='900' color='secondary'>{`#${number}.`}</Text>
                <Text variant='h4' weight='800' color='primary' id="test">
                    {`${title}`}<span style={{ color: '#000' }}>{`]`}</span>
                </Text>
            </Box>
            <div xs={6} style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
                {deleteButton &&
                    <Button variant='outlined' color='secondary' style={{ marginRight: 10 }} onClick={deleteButtonAction}>
                        <Text style={{ fontSize: 12 }}>Excluir</Text>
                    </Button>
                }
                {editButton &&
                    <Button variant='contained' color='primary' onClick={editButtonAction} >
                        <Text style={{ fontSize: 12 }}>Editar</Text>
                    </Button>
                }
                {newButton &&
                    <Button variant='contained' color='primary' onClick={newButtonAction} >
                        <Text style={{ fontSize: 12 }}>Novo</Text>
                    </Button>
                }
                {saveButton &&
                    <Button variant='contained' color='primary' onClick={saveButtonAction} >
                        <Text style={{ fontSize: 12 }}>Salvar</Text>
                    </Button>
                }

            </div>
        </div>
    )

}