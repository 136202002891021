import { createContext, useState } from "react";
import {Backdrop, CircularProgress} from '@mui/material'

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)

    return (
        <AppContext.Provider value={{ setLoading }}>
            {children}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </AppContext.Provider>
    )
}