import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

export const LeftMenu = () => {
  const { checkPermission } = useContext(AuthContext);

  const [selectedItem, setSelectedItem] = useState("");

  return (
    <List style={styles.container}>
      {checkPermission(["task-read"]) && (
        <MenuItem
          to="/tasks"
          select={(to) => setSelectedItem(to)}
          selectedItem={selectedItem}
        >
          <FormatListBulletedIcon />
          Tarefas
        </MenuItem>
      )}
      {checkPermission(["project-read"]) && (
        <MenuItem
          to="/projects"
          select={(to) => setSelectedItem(to)}
          selectedItem={selectedItem}
        >
          <AccountTreeOutlinedIcon />
          Projetos
        </MenuItem>
      )}
      {checkPermission(["user-read"]) && (
        <MenuItem
          to="/users"
          select={(to) => setSelectedItem(to)}
          selectedItem={selectedItem}
        >
          <GroupOutlinedIcon />
          Usuários
        </MenuItem>
      )}
      {checkPermission(["company-read"]) && (
        <MenuItem
          to="/company"
          select={(to) => setSelectedItem(to)}
          selectedItem={selectedItem}
        >
          <BusinessOutlinedIcon />
          Empresas
        </MenuItem>
      )}
      {checkPermission(["dev", "invoice-read"]) && (
        <MenuItem
          to="/invoice"
          select={(to) => setSelectedItem(to)}
          selectedItem={selectedItem}
        >
          <DescriptionOutlinedIcon />
          NFs
        </MenuItem>
      )}
      {checkPermission(["root"]) && (
        <MenuItem
          to="/settings"
          select={(to) => setSelectedItem(to)}
          selectedItem={selectedItem}
        >
          <SettingsOutlinedIcon />
          Configurações
        </MenuItem>
      )}
    </List>
  );
};

const MenuItem = ({ children, to, select, selectedItem }) => {
  const pathname = useLocation().pathname;

  return (
    <Link to={to} style={styles.link}>
      <ListItemButton
        onClick={() => select(to)}
        sx={
          pathname === to
            ? styles.selectedStyle
            : {
                color: "#8862D4",
                "&:hover": {
                  backgroundColor: "#8862D421",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                },
              }
        }
      >
        <ListItemText primary={children} primaryTypographyProps={styles.text} />
      </ListItemButton>
    </Link>
  );
};

const styles = {
  selectedStyle: {
    backgroundColor: "#D1BAFF",
    color: "#181C29",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  container: {
    position: "fixed",
    top: 64,
    left: 0,
    width: 200,
    height: "100%",
    paddingTop: 40,
    paddingRight: 20,
  },
  menuItemContainer: {
    padding: 10,
    paddingLeft: 20,
    fontSize: 14,
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    display: "flex",
    gap: 1,
  },
  link: {
    textDecoration: "none",
    color: "#000",
  },
};
