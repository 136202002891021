import {useHistory} from 'react-router-dom'
import { Div, Paper, SectionHeader, SectionContainer, UserForm} from "../../W3libComponents";

export const UserNew = () => {

  const history = useHistory();
  const handleAfterSave = () => {
    history.push(`/users`)
  }

  return (
    <SectionContainer >
      <SectionHeader title="Novo Usuário" />
      <Div>
        <Paper
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            padding: 20,
          }}
        >
          <UserForm handleAfterSave={handleAfterSave}/>
        </Paper>
      </Div>
    </SectionContainer>
  );
};
