import { Dialog, DialogActions, DialogContent, DialogContentText, Button, Text } from "../../W3libComponents";

export const ConfirmDialog = (props) => {

    const { 
        handleClose, 
        handleConfirm, 
        confirmButton=true,
        cancelButton=true,
        confimButtonLabel = 'Confirmar', 
        cancelButtonLabel = 'Cancelar', 
        question, 
        title = 'w3lib Admin'
    } = props

    return (<>
        <Dialog open={true} onClose={handleClose}>
            <Text style={{ padding: 10, fontSize: 20, fontWeight: 700 }}>{title}</Text>
            <DialogContent>
                <DialogContentText>
                    {question}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {cancelButton && <Button onClick={handleClose} color="secondary" variant='outlined'>{cancelButtonLabel}</Button>}
                {confirmButton && <Button onClick={handleConfirm} color="primary" variant='contained'>{confimButtonLabel}</Button>}
            </DialogActions>
        </Dialog>
    </>
    )
}


