import api from "../../../services/api";
import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Paper, Table, Text, TableBody, TableCell, TableContainer, TableHead, TableRow, SectionHeader, SectionContainer } from "../../W3libComponents";
import moment from "moment";
import { getProjectStatus } from "../../../utils";

export const ProjectList = ({ context }) => {

    const history = useHistory();
    const { setLoading } = context;
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        setLoading(true);
        api
            .get(`/project`)
            .then((response) => {
                setProjects(response.data.projects);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, []);

    return (
        <SectionContainer>
            <SectionHeader
                title="Projetos"
                newButtonAction={() => history.push(`/project/new`)}
                newButton
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}> # </TableCell>
                            <TableCell align="left" sx={{ fontWeight: "bold" }}>Titulo </TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>Autor</TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>Cliente</TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>Status</TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>Data de Criação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects.map((project, index) => (
                            <ProjectListRow project={project} index={index} key={`projectlistrow${index}`} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SectionContainer>
    );
};

const ProjectListRow = ({ project }) => {
    const history = useHistory();
    return (
        <TableRow hover sx={{ cursor: "pointer" }} onClick={() => history.push(`/project/${project?._id}`)}>
            <TableCell align="center"><Text>{project?.number}</Text></TableCell>
            <TableCell align="left" aria-label="Titulo"><Text>{project?.title}</Text></TableCell>
            <TableCell align="center" aria-label="Criador"><Text>{project?.createdBy?.name}</Text></TableCell>
            <TableCell align="center"><Text>{project?.company?.name}</Text></TableCell>
            <TableCell align="center" aria-label="Status"><Text>{getProjectStatus(project?.status)}</Text></TableCell>
            <TableCell align="center" aria-label="Data de Criação"><Text>{moment(project?.createdAt).format('L')}</Text></TableCell>
        </TableRow>
    );
};