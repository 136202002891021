import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { AppBar } from "@mui/material";
import {
   Avatar,
   IconButton,
   Menu,
   MenuItem,
   ChangePasswordDialog,
   Divider
} from "../../W3libComponents";

export const Header = ({ context }) => {
   const { setLoading } = context;
   const { doLogout, user } = useContext(AuthContext);
   const [showNewPasswordDialog, setShowNewPasswordDialog] = useState(false);


   const { name } = user;

   let initials = name
      .split(" ")
      .map((n) => n[0])
      .join("");
   let initials_converted =
      initials.length > 2 ? initials.substr(0, 2) : initials;

   const [anchorEl, setAnchorEl] = useState(null);

   const handleOpenMenu = ({ currentTarget }) => {
      setAnchorEl(currentTarget);
   };

   const handleCloseMenu = () => {
      setAnchorEl(null);
   };

   return (
      <AppBar position="fixed" style={styles.container} >
         <div style={styles.logoContainer}><img src='/logotipo-W3LIB.svg'></img></div>
         {/* <Divider orientation="vertical" variant="middle" flexItem sx={{backgroundColor: '#FFF'}} /> */}
         <div style={styles.menuContainer}>
            {process.env.REACT_APP_ENVIRONMENT === 'development' ? 'Modo Desenvolvimento' : ''}
            {process.env.REACT_APP_ENVIRONMENT === 'preview' ? 'Modo Homologação' : ''}
         </div>
         <div style={styles.userMenuContainer}>
            <IconButton style={styles.IconButton} onClick={handleOpenMenu}>
               <Avatar
                  sx={{ backgroundColor: 'primary.main', width: 42, height: 42 }}>
                  {initials_converted}
               </Avatar>
            </IconButton>
         </div>
         <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
            style={{ marginTop: 10 }}
         >
            <MenuItem onClick={() => setShowNewPasswordDialog(true)}>
               Alterar Senha
            </MenuItem>
            <MenuItem onClick={doLogout}>Sair</MenuItem>
         </Menu>
         {showNewPasswordDialog && (
            <ChangePasswordDialog
               handleClose={() => setShowNewPasswordDialog(false)}
               title="Alterar Senha"
               confimButtonLabel="Alterar Senha"
               question={"Preencha os campos para alterar a senha."}
               setLoading={setLoading}
            // handleConfirm={(resetData) => handleChangePassword(resetData)}
            />
         )}
      </AppBar>
   );
};

const styles = {
   container: {
      height: 84,
      flexDirection: "row",
      zIndex: 10000,
      backgroundColor: '#181C29',
   },
   logoContainer: {
      color: "#FFF",
      display: "flex",
      minWidth: 150,
      alignItems: "center",
      justifyContent: "center",
      fontSize: 22,
      fontWeight: "700",
   },
   menuContainer: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      paddingLeft: 24,
   },
   userMenuContainer: {
      display: "flex",
      alignItems: "center",
      marginRight: 20,
   },
};