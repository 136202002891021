import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import { useContext, useEffect, useReducer, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";
import { AuthContext } from "../../../contexts/AuthContext";
import api from "../../../services/api";
import { TASK_PRIORITIES, TASK_STATUS, getTaskType } from "../../../utils";
import {
  calcDurationInHours,
  priorityArrowAnimation,
} from "../../../utils/tasks";
import {
  AddHourDialog,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  SectionContainer,
  SectionHeader,
  Text,
  TextField,
  UploadBox,
  UserListDialog,
} from "../../W3libComponents";
import { StatusLabel } from "./StatusLabel";

export const TaskDetails = () => {
  const { setLoading } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const history = useHistory();

  const reducer = (prev, next) => [...new Set([...prev, next])];
  const taskReducer = (prev, next) => ({ ...prev, ...next });

  const [task, setTask] = useReducer(taskReducer, {
    documents: [],
    interactions: [],
  });

  const [updatedFields, setUpdatedFields] = useReducer(reducer, []);

  const [newInteraction, setNewInteraction] = useState({
    interaction: "",
    documents: [],
  });

  const [error, setError] = useState(false);
  const [showAddHourDialog, setShowAddHourDialog] = useState(false);
  const [showChangeAssignToDialog, setShowChangeAssignToDialog] =
    useState(false);
  const [showAddParticipantDialog, setShowAddParticipantDialog] =
    useState(false);

  const showSaveButton = updatedFields.length > 0;

  useEffect(() => {
    loadTask();
  }, []);

  const loadTask = () => {
    setLoading(true);
    api
      .get(`/task/${id}`)
      .then((response) => {
        setTask(response.data.task);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    if (name === "interaction") {
      return setNewInteraction({ ...newInteraction, interaction: value });
    }

    setUpdatedFields(name);
    setTask({ [name]: value });
  };

  const handleUpload = ({ documentId }) => {
    let newDocuments = newInteraction.documents;
    newDocuments.push(documentId);

    setNewInteraction({ ...newInteraction, documents: newDocuments });
  };

  const handleChangeAssignTo = (assignToArray) => {
    setShowChangeAssignToDialog(false);
    if (assignToArray.length <= 0) {
      return false;
    }

    const assignTo = assignToArray[0];
    setLoading(true);
    setError(false);

    api
      .post(`/task/${id}/assignTo/${assignTo}`)
      .then((response) => {
        const { task: updatedTask } = response.data;
        setTask(updatedTask);
        let interaction = `${user.name} alterou o responsável para **${updatedTask.assignTo.name}**.`;
        saveInteraction({ msg: { interaction } });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleRemoveParticipant = (idParticipant) => {
    setLoading(true);

    let nameParticipant = task.participants.find(
      (participant) => participant._id === idParticipant
    ).name;
    api
      .delete(`/task/${id}/participant/${idParticipant}`)
      .then((response) => {
        const { task: updatedTask } = response.data;
        let interaction = `${user.name} removeu  o participante **${nameParticipant}** desta tarefa.`;
        saveInteraction({ msg: { interaction } });
        setTask(updatedTask);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleAddParticipant = (participantArray) => {
    setShowAddParticipantDialog(false);

    if (participantArray.length <= 0) {
      return false;
    }
    const newIdParticipant = participantArray[0];
    setLoading(true);
    setError(false);
    api
      .post(`/task/${id}/participant/${newIdParticipant}`)
      .then((response) => {
        const { task: updatedTask } = response.data;
        setTask(updatedTask);
        let nameParticipant = updatedTask.participants.find(
          (participant) => participant._id === newIdParticipant
        ).name;
        let interaction = `${user.name} adicionou **${nameParticipant}** a esta tarefa.`;
        saveInteraction({ msg: { interaction } });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDeteleTask = async (taskId) => {
    setLoading(true);
    try {
      const response = await api.delete(`/task/${taskId}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      history.push("/tasks");
    }
  };

  const saveInteraction = ({ msg = null }) => {
    if (newInteraction.interaction.length < 2 && !msg) {
      setError(true);
      return false;
    }

    setError(false);
    setLoading(true);

    api
      .post(
        `/task/${id}/interaction`,
        msg ? { interaction: msg.interaction } : newInteraction
      )
      .then((response) => {
        loadTask();
        setNewInteraction({ interaction: "", documents: [] });
      })
      .catch((err) => {
        console.log("erro");
      })
      .finally(() => setLoading(false));
  };

  const saveChanges = async () => {
    const showDurationAlert =
      updatedFields.includes("status") &&
      task.status === "closed" &&
      !task?.minutes;

    if (showDurationAlert) {
      alert(
        "Você precisa registrar o tempo de atendimento antes de finalizar a tarefa."
      );
      return;
    }

    const updatedTask = {};

    updatedFields.forEach((field) => (updatedTask[field] = task[field]));

    await api
      .patch(`/task/${id}`, updatedTask)
      .then((res) => {
        alert(res.data.msg);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const priority = Object.values(TASK_PRIORITIES);
  const taskStatus = Object.values(TASK_STATUS);
  // The filter below is temporary
  const employeesFilter = (item) =>
    user.employee ? item : !item.interaction.includes("horas de atendimento");

  return (
    <>
      {task?._id ? (
        <SectionContainer>
          <Grid container sx={{ flexWrap: "nowrap" }}>
            <SectionHeader
              number={task.number}
              title={task.title}
              saveButton={showSaveButton}
              saveButtonAction={saveChanges}
            />
          </Grid>
          <Paper sx={{ padding: 4 }}>
            <Grid container spacing={6}>
              <Grid item xs={9} sx={{ paddingRight: 0 }}>
                <Box sx={{ paddingBottom: 2 }}>
                  <Text
                    sx={{
                      fontSize: 16,
                      fontWeight: 700,
                    }}
                    color="secondary"
                  >
                    Descrição
                  </Text>
                  {<ReactMarkdown children={task.description} />}
                </Box>
                <Text
                  sx={{
                    fontSize: 16,
                    fontWeight: 700,
                    paddingBottom: 1,
                    marginTop: 2,
                  }}
                  color="secondary"
                >
                  Interações
                </Text>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  {task.interactions
                    .filter(employeesFilter)
                    .map((item, index) => {
                      return (
                        <Box
                          key={`taskinteraction${index}`}
                          sx={{
                            borderColor: "#8862D455",
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderRadius: 1,
                            marginBottom: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor: "#8862D422",
                              padding: 2,
                              color: "#7a7d9a",
                              gap: 0.4,
                            }}
                          >
                            <Text sx={{ fontSize: 14 }}>Por</Text>
                            <Text sx={{ fontSize: 14, fontWeight: 600 }}>
                              {item.createdBy.name}
                            </Text>
                            <Text sx={{ fontSize: 14 }}>em</Text>
                            <Text sx={{ fontSize: 14, fontWeight: 600 }}>
                              {moment(item?.createdAt).format(
                                "DD.MM.YYYY, HH:mm"
                              )}
                            </Text>
                          </Box>
                          <Box sx={{}}>
                            <Text sx={{ padding: 2, fontSize: 14 }}>
                              {<ReactMarkdown children={item.interaction} />}
                            </Text>
                          </Box>
                          {item.documents.map((document, index) => (
                            <Text
                              key={`interactiondocument${index}`}
                              sx={{
                                fontSize: 12,
                                paddingLeft: 2,
                                paddingBottom: 2,
                              }}
                            >
                              <a target="_blank" href={document.url}>
                                {document.name}
                              </a>
                            </Text>
                          ))}
                        </Box>
                      );
                    })}
                </Box>
                {(task.status === "open" ||
                  task.status === "standBy" ||
                  task.status === "revision") && (
                  <>
                    <UploadBox
                      handleUpload={handleUpload}
                      newInteraction={newInteraction}
                    >
                      <TextField
                        name="interaction"
                        label="Digite sua interação ou arraste um arquivo aqui"
                        value={newInteraction?.interaction}
                        minRows={5}
                        maxRows={10}
                        multiline
                        required
                        error={error}
                        helperText={
                          error
                            ? "O campo de interação deve contar no mínimo 2 caracteres"
                            : ""
                        }
                        onChange={({ target }) =>
                          handleChange(target.name, target.value)
                        }
                      />
                    </UploadBox>
                    <Box>
                      {newInteraction.documents.map((item, index) => (
                        <Text
                          key={`interactionnewdocument${index}`}
                          sx={{ fontSize: 12 }}
                        >
                          <a target="_blank" href={"#"}>
                            Arquivo {index + 1}
                          </a>
                        </Text>
                      ))}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="outlined"
                        onClick={saveInteraction}
                        style={{ height: 45, marginTop: 5 }}
                      >
                        Adicionar Interação
                      </Button>
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item xs={3}>
                <Box>
                  <Text sx={{ fontSize: 12 }}>Cliente:</Text>
                  <Text sx={{ fontSize: 14, marginBottom: 2, fontWeight: 700 }}>
                    {task?.company?.name}
                  </Text>
                  <Text sx={{ fontSize: 12, marginTop: 2 }}>Status</Text>
                  <StatusLabel
                    currentStatus={task.status}
                    dropDown
                    dropDownOptions={taskStatus}
                    dropDownOptionAction={({ value }) => {
                      handleChange("status", value);
                    }}
                    arrow="/priority-arrow.svg"
                    arrowId="status"
                    arrowAnimation={(query) => priorityArrowAnimation(query)}
                  />
                  <Text sx={{ fontSize: 12, marginBottom: 0.5 }}>
                    Prioridade:
                  </Text>
                  <StatusLabel
                    currentStatus={task.priority}
                    dropDown
                    dropDownOptions={priority}
                    dropDownOptionAction={({ value }) => {
                      handleChange("priority", value);
                    }}
                    arrow="/priority-arrow.svg"
                    arrowId="priority"
                    arrowAnimation={(query) => priorityArrowAnimation(query)}
                  />
                  <Text sx={{ fontSize: 12, marginTop: 2 }}>Aberto por:</Text>
                  <Text sx={{ fontSize: 14, marginBottom: 2, fontWeight: 700 }}>
                    {task?.createdBy?.name}
                  </Text>
                  <Text sx={{ fontSize: 12 }}>Data de Criação</Text>
                  <Text sx={{ fontSize: 14, marginBottom: 2, fontWeight: 700 }}>
                    {moment(task?.createdAt).format("DD-MM-YYYY HH:mm")}
                  </Text>
                  <Text sx={{ fontSize: 12 }}>Responsável:</Text>
                  <Text sx={{ fontSize: 14, marginBottom: 2, fontWeight: 700 }}>
                    {task?.assignTo?.name}
                  </Text>
                  <Text sx={{ fontSize: 12 }}>Participantes:</Text>
                  {task.participants?.map((participant, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        fontSize: "small",
                      }}
                    >
                      <Text
                        key={`taskparticipant${index}`}
                        sx={{ fontSize: 14, fontWeight: 700 }}
                      >
                        {participant.name}
                      </Text>
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveParticipant(participant._id)}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                  <Text sx={{ fontSize: 12 }}>Tipo de Tarefa</Text>
                  <Text sx={{ fontSize: 14, marginBottom: 2, fontWeight: 700 }}>
                    {getTaskType(task?.type)}
                  </Text>
                  {task.minutes > 0 && user.employee && (
                    <>
                      <Text sx={{ fontSize: 12 }}>Tempo de Atendimento</Text>
                      <Text
                        sx={{ fontSize: 14, marginBottom: 2, fontWeight: 700 }}
                      >
                        {calcDurationInHours(task.minutes)}
                        {/* {moment()
                          .startOf("day")
                          .add(task?.minutes, "minutes")
                          .format("H:mm")} */}
                      </Text>
                    </>
                  )}
                  {task.documents.length > 0 && (
                    <>
                      <Text sx={{ fontSize: 12 }}>Anexos</Text>
                      {task.documents.map((document, index) => (
                        <Text
                          key={`taskdocument${index}`}
                          sx={{ fontSize: 12 }}
                        >
                          <a target="_blank" href={document.url}>
                            {document.name}
                          </a>
                        </Text>
                      ))}
                    </>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    {task.status !== "closed" && (
                      <>
                        <Box
                          sx={{
                            marginTop: 1,
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            marginTop: 1,
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => setShowAddHourDialog(true)}
                            style={{ width: "100%", height: 45 }}
                          >
                            Registrar Tempo
                          </Button>
                        </Box>

                        <Box
                          sx={{
                            marginTop: 1,
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => setShowChangeAssignToDialog(true)}
                            style={{ width: "100%", height: 45 }}
                          >
                            Alterar Responsável
                          </Button>
                        </Box>

                        <Box
                          sx={{
                            marginTop: 1,
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => setShowAddParticipantDialog(true)}
                            style={{ width: "100%", height: 45 }}
                          >
                            Adicionar Participante
                          </Button>
                        </Box>

                        <Box
                          sx={{
                            marginTop: 1,
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => handleDeteleTask(task._id)}
                            style={{ width: "100%", height: 45 }}
                          >
                            Excluir Tarefa
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
          {showAddHourDialog && (
            <AddHourDialog
              handleClose={() => setShowAddHourDialog(false)}
              handleConfirm={(minutes) => {
                saveInteraction({
                  msg: {
                    interaction: `${user.name} registrou ${calcDurationInHours(
                      minutes
                    )} de atendimento deste chamado`,
                  },
                });
                setShowAddHourDialog(false);
                loadTask();
              }}
              taskId={id}
            />
          )}
          {showChangeAssignToDialog && (
            <UserListDialog
              handleClose={() => setShowChangeAssignToDialog(false)}
              handleSubmit={handleChangeAssignTo}
              newContactButton={false}
            />
          )}
          {showAddParticipantDialog && (
            <UserListDialog
              handleClose={() => setShowAddParticipantDialog(false)}
              handleSubmit={handleAddParticipant}
              newContactButton={false}
            />
          )}
        </SectionContainer>
      ) : (
        <></>
      )}
    </>
  );
};
