import { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../contexts/AppContext'
import { useDropzone } from 'react-dropzone'
import { Box, IconButton } from '../../W3libComponents'
import api from '../../../services/api'
import CloseIcon from '@mui/icons-material/Close';
import { ErrorDialog } from '../error/ErrorDialog'

export const UploadBox = ({ children, handleUpload, sx = {}, preview = false, company = {}, newInteraction = {} }) => {

    const { setLoading } = useContext(AppContext)

    const [files, setFiles] = useState([]);
    const [showErrorDialog, setShowErrorDialog] = useState(false)

    const handleDeleteTempImages = () => {
        setFiles([])
    };

    const kindOfDropDependency = () => {
        if (preview) {
            return company
        }
        return newInteraction
    }
    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));

        const formData = new FormData();
        formData.append("file", acceptedFiles[0], acceptedFiles[0].name)
        setLoading(true);

        api.post(`/document`, formData).then((response) => {
            const { _id } = response.data.upload
            const { url } = response.data.upload

            handleUpload({ documentId: _id, urlLogo: url })
            setLoading(false);
        }).catch((err) => {
            setShowErrorDialog(true)
            setLoading(false)
        });

    }, [kindOfDropDependency])

    const { getRootProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1, 
    })

    useEffect(
        () => () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return (
        <Box sx={{ backgroundColor: isDragActive ? '#e1e1e1' : '#FFF', ...sx }} {...getRootProps()}>
            {preview && files[0] &&
                <Box sx={{ height: '100%', width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <IconButton
                        size="small"
                        onClick={() => handleDeleteTempImages(files[0])}
                        style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            backgroundColor: '#e1e1e1'
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    <Box sx={{ maxWidth: 120, maxHeight: 120 }}>
                        <img src={files[0].preview} style={{ height: "100%", width: '100%', objectFit: 'contain' }} alt="Logo da temporária" />
                    </Box>
                </Box>
            }
            {children}
            {showErrorDialog &&
                <ErrorDialog
                    title="Erro no upload"
                    msg="Ocorreu um erro durante o upload, verifique o formato do arquivo e tente novamente. Limite de Upload 10mb*"
                    handleClose={() => setShowErrorDialog(false)}
                />
            }
        </Box>
    )
}