import { useState } from "react"
import { Box, Text } from "../../W3libComponents"
import { useStatusLabel } from "../../../hooks/useStatusLabel"

export const StatusLabel = (props) => {
   const {
      arrow,
      arrowAnimation,
      priorityArrow,
      arrowId,
      dropDown,
      dropDownOptionAction,
      dropDownOptionsFiltered,
      status,
   } = useStatusLabel(props)

   const [showDropDownOptions, setShowDropDownOptions] = useState(false)

   return (
      <Box style={styles.mainContainer}>
         <Box
            onClick={() => {
               if (dropDown) {
                  arrowAnimation(priorityArrow)
                  setShowDropDownOptions(!showDropDownOptions)
               }
            }}
            sx={{
               ...styles.labelContainer,
               backgroundColor: '#eee',
               display: "flex",
               alignItems: "center",
               borderLeft: `8px solid ${status?.color}`,
               ...(dropDown && {
                  "&:hover": {
                     backgroundColor: '#dfdfdf',
                     cursor: 'pointer'
                  }
               })
            }}
         >
            <Box style={styles.labelTextContainer}>
               <Text style={styles.regularText}>{status.text}</Text>
            </Box>
            {dropDown && (
               <img
                  src={arrow}
                  alt="arrow"
                  id={`${dropDown ? arrowId : ""}`}
                  height={10}
                  width={10}
                  style={{ marginRight: 5, transform: 'rotate(90deg)' }}
               />
            )}
         </Box>
         {showDropDownOptions &&
            <Box style={styles.dropDownOptionsContainer}>
               <Box style={styles.dropDownOptionsDivider}
               />
               {dropDownOptionsFiltered.map((option, index) =>
                  <Box
                     key={`${index}_dropDownOption_${option.text}`}
                     onClick={() => {
                        setShowDropDownOptions(false)
                        dropDownOptionAction(option)
                        arrowAnimation(priorityArrow)
                     }}
                     sx={{
                        ...styles.dropDownCell,
                        backgroundColor: '#eee',
                        borderLeft: `8px solid ${option?.color}`,
                        ...(dropDown && {
                           "&:hover": {
                              backgroundColor: '#dfdfdf',
                              cursor: 'pointer'
                           }
                        })
                     }}
                  >
                     <Box style={styles.dropDownOptionTextContainer}>
                        <Text style={styles.regularText}>
                           {option.text}
                        </Text>
                     </Box>
                     <Box style={styles.paddingRight} />
                  </Box>
               )}
            </Box>
         }
      </Box>
   )
}

const styles = {
   mainContainer: {
      marginBottom: 3,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
   },
   labelContainer: {
      display: 'flex',
      borderRadius: 1,
      width: 120,
      overflow: 'hidden',
      padding: `8px 4px`,
   },
   regularText: {
      fontSize: 12,
      textTransform: 'uppercase',
      fontWeight: 'bolder'
   },
   labelTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      marginLeft: 3
   },
   arrowDropDownContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: 3,
      backgroundColor: 'tomato'
   },
   iconContainer: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: 13,
      height: 13,
      "&:hover": {
         cursor: 'pointer',
         opacity: 1,
      }
   },
   dropDownOptionsContainer: {
      position: 'absolute',
      top: 0,
      marginTop: 34,
      backgroundColor: '#fff',
      zIndex: 99
   },
   dropDownOptionsDivider: {
      width: '100%',
      height: 1,
      backgroundColor: '#ddd',
      marginTop: 6,
      marginBottom: 6
   },
   dropDownCell: {
      display: 'flex',
      borderRadius: 1,
      width: 120,
      overflow: 'hidden',
      padding: `8px 4px`,
      marginBottom: '2px',
   },
   dropDownOptionTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      marginLeft: 3
   },
   paddingRight: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: 3,
   }
}