import { useState, useEffect, useContext } from 'react'
import { AppContext } from "../../../contexts";
import api from '../../../services/api'
import { Accordion, AccordionSummary, AccordionDetails, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, Text, Checkbox } from '../../W3libComponents'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const PermissionListDialog = (props) => {

    const { setLoading } = useContext(AppContext);
    const [permissions, setPermissions] = useState([])
    const [selectedPermissions, setSelectedPermissions] = useState([])
    const [filter, setFilter] = useState('')
    const [indexMouseOver, setIndexMouseOver] = useState(null)

    const {
        title = 'Selecione a permissão',
        cancelButton = true,
        handleClose,
        userId,
        handleSubmit
    } = props

    useEffect(() => {
        loadPermissions()
    }, [])

    const loadPermissions = () => {
        setLoading(true)

        api.get('/permission').then(response => {
            const { groups } = response.data.permissions
            setLoading(false)
            setPermissions(groups)
        }).catch(err => {
            setLoading(false)
            console.log(err);
        })
    }

    const handleChangeFilter = ({ target }) => {
        const { value } = target;
        setFilter(value)
    }

    const handleAddPermissions = () => {
        setLoading(true)
        api.post(`/user/${userId}/permission`, { permissions: selectedPermissions }).then(response=>{
            setLoading(false)
            handleSubmit()
        }).catch(err=>{
            setLoading(true)
        })
    }

    const handleCheckboxChange = ({ id, target }) => {

        const { checked } = target
        if (checked)
            setSelectedPermissions([...selectedPermissions, id])

        if (!checked)
            setSelectedPermissions(selectedPermissions.filter(item => item != id))
    }

    return (
        <>
            <Dialog open={true} maxWidth='md' fullWidth sx={{ marginTop: 6 }} >
                <DialogTitle>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ flex: 1 }}>{title}</Box>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <TextField
                            variant="outlined"
                            refs="filter"
                            name="filter"
                            margin="dense"
                            placeholder='Buscar'
                            fullWidth
                            autoFocus
                            onChange={handleChangeFilter}
                        />
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {permissions.map((permissionGroup, index) => {

                        const filteredPermisisons = permissionGroup.permissions.filter(p => p.description.includes(filter) || p.id.includes(filter))

                        return (
                            <>
                                {filteredPermisisons.length > 0 &&
                                    <Accordion disableGutters key={`permissions${index}`} expanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Text sx={styles.groupTitle}>{permissionGroup.name}</Text>
                                        </AccordionSummary>
                                        {filteredPermisisons.map((item) =>
                                            <AccordionDetails sx={styles.permissionText}>
                                                <Box sx={styles.permissionContainer}>
                                                    <Box sx={styles.permissionTitleContainer}>{item.description}</Box>
                                                    <Box sx={styles.permissionCheckContainer}><Checkbox onChange={({ target }) => handleCheckboxChange({ id: item.id, target })} sx={{ padding: 0 }} /></Box>
                                                </Box>
                                            </AccordionDetails>
                                        )}
                                    </Accordion>
                                }
                            </>
                        )
                    }
                    )}
                </DialogContent>
                <DialogActions>
                    {cancelButton && <Button onClick={handleClose}>Cancelar</Button>}
                    <Button onClick={handleAddPermissions} variant="contained" size="small">Adicionar Permissões</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const styles = {
    groupTitle: {
        fontWeight: '700',
        fontSize: 12
    },
    permissionText: {
        fontSize: 12,
        padding: 1,
        paddingLeft: 2,
        paddingRight: 2
    },
    permissionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    permissionTitleContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }

}