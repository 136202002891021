const WORDS_LIBRARY = {};

export const TASK_STATUS = {
  open: {
    value: "open",
    text: "Aberta",
    color: "#02ae23",
  },
  standBy: {
    value: "standBy",
    text: "Stand-By",
    color: "#d9d9d9",
  },
  closed: {
    value: "closed",
    text: "Fechada",
    color: "#e3430a",
  },
  revision: {
    value: "revision",
    text: "Revisão",
    color: "#fc992b",
  },
};

export const TASK_PRIORITIES = {
  1: {
    value: 1,
    text: "Baixa",
    color: "#71a0e6",
  },
  2: {
    value: 2,
    text: "Normal",
    color: "#02ae23",
  },
  3: {
    value: 3,
    text: "Alta",
    color: "#FFC003",
  },
  4: {
    value: 4,
    text: "Urgência",
    color: "#e3430a",
  },
};

export const getStatus = (status) => {
  if (status === "open") return "Aberto";
  if (status === "closed") return "Finalizado";
  if (status === "revision") return "Revisão";
  return status;
};

export const getProjectStatus = (status) => {
  if (status === "opened") return "Aberto";
  if (status === "closed") return "Finalizado";
  return status;
};

export const getTaskType = (type) => {
  if (type === "task") return "Solicitação";
  if (type === "error") return "Erro";
  return type;
};

export const filterByKey = (arr = [], field, value) => {
  const arrField = field.split(".");

  return arr.filter((item) => {
    if (value) {
      if (arrField.length > 1) return item[arrField[0]][arrField[1]] === value;

      return item[arrField[0]] === value;
    }

    return true;
  });
};

export const filterParticipants = (arr = [], field, value) => {
  const arrField = field.split(".");
  return arr.filter((item) => {
    if (value) {
      if (arrField.length > 1)
        return item.participants.some(
          (participant) =>
            participant[arrField[1]] === value ||
            item.assignTo[arrField[1]] === value ||
            item.createdBy[arrField[1]] === value
        );

      return (
        item.participants.some(
          (participant) => participant[arrField[1]] === value
        ) ||
        item.assignTo[arrField[1]] === value ||
        item.createdBy[arrField[1]] === value
      );
    }

    return true;
  });
};

// export const formatTimestamp = ({ timestamp, type = 'createdAt', showTime = true, showSeconds = true }) => {

//     if (timestamp) {
//           let newDate = new Date(timestamp)
//           newDate.setDate(newDate.getDate() + 1)
//           newDate.setHours(newDate.getHours() - 4)
//           newDate.setMinutes(newDate.getMinutes() + 10)
//           let seconds = showSeconds ? { second: '2-digit' } : {}
//           let formatedDate = newDate.toLocaleTimeString('pt-BR', {
//              year: 'numeric',
//              month: 'numeric',
//              day: 'numeric',
//              hour: '2-digit',
//              minute: '2-digit',
//              ...seconds
//           })
//           return showTime ? formatedDate.replace(" ", " às ") : formatedDate.split(" ")[0]
//     }
//     return '-'
//  }

export const pluralize = (word, count) => {
  if (word && count) {
    return Number(count) > 1 ? WORDS_LIBRARY[word] || word + "s" : word;
  }
};
