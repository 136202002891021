import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import { Div, Box, Grid, Text, Paper, Button, SectionHeader, SectionContainer, TextField, UploadBox } from "../../W3libComponents";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const CompanyEdit = ({ context }) => {

    const { setLoading } = context
    const history = useHistory();
    const { id } = useParams();
    const [company, setCompany] = useState({
        name: '', urlCompanyPage: '', urlLogo: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCompany({
            ...company,
            [name]: value,
        });
    };

    useEffect(() => {
        loadCompany()
    }, []);

    const loadCompany = () => {
        setLoading(true)
        api.get(`/company/${id}`).then((response) => {
            setLoading(false)
            setCompany(response.data.company);
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });
    }

    const handleSave = () => {

        setLoading(true)
        api.patch(`/company/${id}`, { company }).then((response) => {
            setLoading(false)
            history.push(`/company/${id}`)
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });
    }

    const handleDeleteThumb = () => {
        setCompany({ ...company, urlLogo: null })
    };

    const handleUpload = ({urlLogo}) => {
        setCompany({ ...company, urlLogo: urlLogo })
    }

    return (
        <SectionContainer >
            <SectionHeader title={company.name} />
            <Div>
                <Paper
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        padding: 20,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextField
                                variant="outlined"
                                label="Nome da Empresa"
                                refs="name"
                                name="name"
                                margin="dense"
                                fullWidth
                                value={company.name}
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                label="Webpage"
                                refs="urlCompanyPage"
                                name="urlCompanyPage"
                                margin="dense"
                                fullWidth
                                value={company.urlCompanyPage}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid xs={4} sx={{ paddingLeft: 2, marginTop: 2 }}>

                            {!company.urlLogo &&
                                <UploadBox company={company} preview={true} handleUpload={handleUpload} sx={{ display: 'flex', border: '1px dashed lightgray', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    <Text>Arraste uma imagem aqui</Text>
                                </UploadBox>
                            }
                            {company.urlLogo &&
                                <Box sx={{ height: '100%', width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleDeleteThumb(company.urlLogo)}
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            top: 0,
                                            backgroundColor: '#e1e1e1'
                                        }}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                    <Box sx={{ maxWidth: 120, maxHeight: 120 }}>
                                        <img src={company.urlLogo} style={{ height: "100%", width: '100%', objectFit: 'contain' }} alt="Logo da Empresa" />
                                    </Box>
                                </Box>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Div style={{ justifyContent: "flex-end" }}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    style={{ bottom: 0 }}
                                    onClick={handleSave}
                                >
                                    <Text style={{ fontSize: 12 }}>Salvar</Text>
                                </Button>
                            </Div>
                        </Grid>
                    </Grid>
                </Paper>
            </Div>
        </SectionContainer>
    );
};
