export const validationResetPasswordForm = (values) => {
    if (values.password !== values.confirmPassword) return false

    if (values.emailToken === "" || values.emailToken === null || values.emailToken === undefined) return false

    if (values.password === "" || values.password === null || values.password === undefined) return false

    if (values.confirmPassword === "" || values.confirmPassword === null || values.confirmPassword === undefined) return false

    return true
}