import {useState, useEffect } from 'react'
import api from '../../../services/api';
import {FormControl, InputLabel, Select, MenuItem} from '../../W3libComponents'

export const CompanySelect = (props) => {

    const {
        handleSelect,
        label='Empresa'
    } = props

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
    
        api.get('/company').then(response => {
          const { companies } = response.data
          setCompanies(companies)
        })

      }, [])

    return (
        <FormControl fullWidth >
            <InputLabel required id="company">Cliente</InputLabel>
            <Select
                name="company"
                labelId="company"
                id="company"
                label={label}
                onChange={handleSelect}
            >
                {companies.map(({ name, _id }, index) => (
                    <MenuItem key={`company${index}`} value={_id}>{name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}