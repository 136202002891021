import api from "../../../services/api";
import { useState, useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import {
  Grid,
  TextField,
  Button,
  Div,
  Text,
  Checkbox,
  Box,
} from "../../W3libComponents";

export const UserForm = (props) => {
  const { handleAfterSave = () => {} } = props;

  const { setLoading } = useContext(AppContext);
  const [user, setUser] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSave = () => {
    setLoading(true);
    const body = {
      user: { name: user.name, email: user.email, employee: user.employee },
    };

    api
      .post(`/user`, body)
      .then((res) => {
        setLoading(false);
        handleAfterSave();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data);
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label="Nome"
          refs="name"
          name="name"
          margin="dense"
          fullWidth
          autoFocus
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          type="email"
          variant="outlined"
          label="Email"
          refs="email"
          name="email"
          margin="dense"
          fullWidth
          onChange={handleChange}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: "flex", flexDirection: "column", gap: 4 }}
      >
        <Box style={{ display: "flex", gap: 4 }}>
          <Checkbox
            name="employee"
            onChange={({ target }) => {
              handleChange({
                target: { name: target.name, value: target.checked },
              });
            }}
            sx={{ padding: 0 }}
          />
          <Text>Colaborador</Text>
        </Box>
        <Text style={{ fontSize: 13, color: "#888" }}>
          Colaboradores podem ver dados de tempo investido nas tarefas.
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Div style={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            style={{ bottom: 0 }}
            onClick={handleSave}
          >
            <Text style={{ fontSize: 12 }}>Cadastrar</Text>
          </Button>
        </Div>
      </Grid>
    </Grid>
  );
};
