import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: "#8862D4",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#181C29",
            contrastText: "#FFFFFF",
        }
    }
})

export default theme