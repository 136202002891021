import api from "../../../services/api";
import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, SectionHeader, SectionContainer, Text} from "../../W3libComponents";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export const UserList = ({ context }) => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [sortBy, setSortBy] = useState({ key: "", order: "" });
  const { setLoading, checkPermission } = context

  useEffect(() => {
    setLoading(true)
    api
      .get(`/user`)
      .then((response) => {
        setLoading(false)
        setUsers(response.data.users);
      })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      });
  }, []);

  const handleNewUser = useCallback(() => history.push(`/user/new`), [history]);

  const handleSort = (key) => {
    if (key === sortBy.key) {
      setSortBy({ key, order: sortBy.order === "asc" ? "desc" : "asc" });
    } else {
      setSortBy({ key, order: "asc" });
    }
  };

  const sortedUsers = users.sort((a, b) => {
    if (sortBy.order === "asc") {
      return a[sortBy.key] > b[sortBy.key] ? 1 : -1;
    } else {
      return a[sortBy.key] < b[sortBy.key] ? 1 : -1;
    }
  });

  return (
    <SectionContainer>
      <SectionHeader
        title="Usuários"
        newButtonAction={() => handleNewUser()}
        newButton={checkPermission(['user-create'])}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={{backgroundColor:"#181C29"}} >
            <TableRow>
              <TableCell align="center" style={{ color:'whitesmoke', fontSize: 16, fontWeight: "bold", cursor: "pointer" }} onClick={() => handleSort("name")}>
                {" "}
                Nome{" "}
                {sortBy.key === "name" && sortBy.order === "asc" && <ArrowUpwardIcon />}
                {sortBy.key === "name" && sortBy.order === "desc" && <ArrowDownwardIcon />}
              </TableCell>
              <TableCell align="center" style={{ color:'whitesmoke', fontSize: 16, fontWeight: "bold", cursor: "pointer" }} onClick={() => handleSort("email")}>
                {" "}
                E-mail
                {sortBy.key === "email" && sortBy.order === "asc" && <ArrowUpwardIcon />}
                {sortBy.key === "email" && sortBy.order === "desc" && <ArrowDownwardIcon />}
              </TableCell>
              <TableCell align="center" style={{ color:'whitesmoke', fontSize: 16, fontWeight: "bold", cursor: "pointer"}} onClick={() => handleSort("active")}>
                {" "}
                Status
                {sortBy.key === "active" && sortBy.order === "asc" && <ArrowUpwardIcon />}
                {sortBy.key === "active" && sortBy.order === "desc" && <ArrowDownwardIcon />}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <UserListRow user={user} key={`userrow${index}`} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </SectionContainer>
  );
};

const UserListRow = ({ user, index }) => {
  const history = useHistory();
  const handleUser = useCallback(
    (id) => history.push(`/user/${id}`),
    [history]
  );

  return (
    <TableRow
      hover
      sx={{ cursor: "pointer",
      backgroundColor: index % 2 === 0 ? "white" : "#8862D421",}}
      onClick={() => handleUser(user?._id)}
    >
      <TableCell align="center" aria-label="Nome">
        <Text sx={{ fontSize: 14 }}>{user?.name}</Text>
      </TableCell>
      <TableCell align="center" aria-label="E-mail">
          <Text sx={{ fontSize: 14 }}>{user?.email}</Text>
      </TableCell>
      <TableCell align="center" aria-label="E-mail">
          <Text sx={{ fontSize: 14, color: `${user?.active ? 'green' : 'red'}` }}>{user?.active ? 'Ativo' : 'Inativo'}</Text>
      </TableCell>
    </TableRow >
  );
};
