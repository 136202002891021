import { useState } from 'react'
import { TextField } from '../../W3libComponents';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box } from '../../W3libComponents';

export const DateRangeInput = (props) => {

  const {
    startText = 'Início',
    endText = 'Fim',
    handleChangeDate
  } = props
  const [value, setValue] = useState([null, null]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText={startText}
        endText={endText}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          handleChangeDate(newValue)
        }}
        renderInput={(startProps, endProps) => (
          <>
            <TextField size='small' {...startProps} />
            <Box sx={{ mx: 2 }}> até </Box>
            <TextField size='small' {...endProps} />
          </>
        )}
      />
    </LocalizationProvider>
  );
}