import { useState, useEffect, useContext } from 'react'
import { AppContext } from "../../../contexts";
import api from '../../../services/api'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableRow, TableCell, Box, CompanyNewDialog } from '../../W3libComponents'

export const CompanyListDialog = (props) => {

    const { setLoading } = useContext(AppContext);
    const [companies, setCompanies] = useState([])
    const [indexMouseOver, setIndexMouseOver] = useState(null)
    const [showCompanyNewDialog, setShowCompanyNewDialog] = useState(false)

    const {
        title = 'Selecione a Empresa',
        cancelButton = true,
        handleClose,
        handleSubmit,
        newCompanyButton = true
    } = props

    useEffect(() => {
        loadCompanies()
    }, [])

    const loadCompanies = () => {
        setLoading(true)

        api.get('/company').then(response => {
            setLoading(false)
            setCompanies(response.data.companies)
        }).catch(err => {
            setLoading(false)
            console.log(err);
        })
    }

    const handleAfterSave = () => {
        setShowCompanyNewDialog(false)
        loadCompanies()
    }

    return (
        <>
            <Dialog open={true} maxWidth='md' fullWidth sx={{marginTop: 6}} >
                <DialogTitle>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ flex: 1 }}>{title}</Box>
                        <Box>{newCompanyButton && <Button size='small' onClick={() => setShowCompanyNewDialog(true)}>Nova Empresa</Button>}</Box>
                    </Box>

                </DialogTitle>
                <DialogContent>
                    {companies.map((company, index) => (
                        <Table size='small'>
                            <TableBody>
                                <TableRow hover>
                                    <TableCell
                                        onMouseOver={() => setIndexMouseOver(index)}
                                    >
                                        {company.name}
                                    </TableCell>
                                    <TableCell
                                        onMouseOver={() => setIndexMouseOver(index)}
                                        sx={{ width: 100, height: 28.75 }}>
                                        {indexMouseOver === index &&
                                            <Button
                                                sx={{ padding: '3px', paddingLeft: '10px', paddingRight: '10px' }}
                                                size='small'
                                                variant='contained'
                                                onClick={() => handleSubmit([company._id])}>
                                                Selecionar
                                            </Button>
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    ))}
                </DialogContent>
                <DialogActions>
                    {cancelButton && <Button  onClick={handleClose}>Cancelar</Button>}
                </DialogActions>
            </Dialog>
            {showCompanyNewDialog &&
                <CompanyNewDialog
                    handleAfterSave={handleAfterSave}
                    handleClose={() => setShowCompanyNewDialog(false)} />
            }
        </>
    )
}