import { TextField as MUITextField } from "@mui/material";

export const TextField = (props) => {

    const {name} = props

    const defaultProps = {
        variant: 'outlined',
        refs: name,
        name: name,
        fullWidth: true,
        margin: 'dense'
    }

    return (<MUITextField {...defaultProps} {...props} />)
}