import { useContext, useState } from 'react'
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Paper, TextField } from "@mui/material"
import { ErrorDialog, Text } from '../../W3libComponents'
import { useHistory } from "react-router-dom";

export const Login = ({ context }) => {

   const history = useHistory()
   const { setLoading } = context
   const { doLogin } = useContext(AuthContext)
   const [error, setError] = useState(null)
   const [user, setUser] = useState({ email: '', password: '' })

   const handleChange = ({ currentTarget }) => {
      setUser({ ...user, [currentTarget.name]: currentTarget.value })
   }

   const handleLogin = () => {
      if (user.email === '') return false
      if (user.password === '') return false

      doLogin(user, handleError)
   }

   const handleError = (err) => {
      setError(err.data.msg)
      setLoading(false)
   }

   return (
      <div style={styles.container}>
         <div style={styles.imageBackground} />
         <div style={styles.opacityContainer} />
         <Paper style={styles.loginContainer}>
            <div style={styles.logoContainer}>
               <img style={styles.logo} alt='W3LIB Logo' src='logotipo-W3LIB.svg' />
            </div>
            <TextField
               placeholder='E-mail'
               style={styles.textField}
               value={user.email}
               onChange={handleChange}
               name='email'
               InputProps={{ style: { backgroundColor: '#f4f4f4' } }}
            />
            <TextField
               placeholder='Senha'
               style={styles.textField}
               value={user.password}
               onChange={handleChange}
               name='password'
               type="password"
               InputProps={{ style: { backgroundColor: '#f4f4f4' } }}
            />
            <Button onClick={handleLogin} variant='contained' size='large' style={{ marginTop: 20 }}>Entrar</Button>
            <Text sx={{ fontSize: 12 }} style={styles.forgotPassword} onClick={() => history.push("/forgot-password")}>
               Esqueci minha senha
            </Text>
         </Paper>
         {error &&
            <ErrorDialog msg={error} title='w3lib Admin' handleClose={() => setError(null)} />
         }
      </div>
   )
}

const styles = {
   container: {
      flex: 1,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#181C29',
   },
   imageBackground: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage: "url('wallpaperlogin_2.jpg')",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      opacity: 0.35,
   },
   loginContainer: {
      position: 'absolute',
      margin: 'auto',
      flexDirection: 'column',
      padding: `20px 45px 45px 45px`,
      display: 'flex',
      width: 340,
      backgroundColor: '#181C29',
      borderRadius: 10,
      border: `3px solid #8862D477`,
   },
   logoContainer: {
      padding: 40,
      paddingBottom: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
   },
   textField: {
      marginBottom: 10,
      borderRadius: 5,
      backgroundColor: '#D1BAFF'
   },
   logo: {
      width: 120,
   },
   opacityContainer: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(2px)',
      WebkitBackdropFilter: 'blur(2px)'
   },
   forgotPassword: {
      cursor: "pointer",
      marginTop: "15px",
      textAlign: "center",
      textDecoration: "underline",
      color: "#fff"
   }
}