import { Button, Paper, TextField } from "@mui/material"
import { ErrorDialog, Text } from '../../W3libComponents'
import { useContext, useEffect, useState } from "react"
import api from "../../../services/api"
import { useHistory, useParams } from "react-router-dom";
import { validationResetPasswordForm } from "../../../utils/auth";
import { AppContext } from "../../../contexts";

export const ResetPassword = () => {

    const { token } = useParams();
    const history = useHistory()
    const { setLoading } = useContext(AppContext)
    const [user, setUser] = useState({
        emailToken: '',
        password: '',
        confirmPassword: ''
    })
    const [error, setError] = useState(null)

    useEffect(() => {
        setLoading(true)
        api.post("/validation/forgot-password", { token })
            .then(() => setLoading(false))
            .catch(error => {
                if (error.response.data.message) {
                    setError(error.response.data.message);
                    history.push("/")
                }
                setLoading(false)
                console.log(error)
            })
    }, [])

    const handleChange = ({ currentTarget }) => setUser({ ...user, [currentTarget.name]: currentTarget.value })

    const handleResetPassword = () => {
        setLoading(true)
        const isFormCorrect = validationResetPasswordForm(user)

        if (!isFormCorrect) {
            setLoading(false)
            return
        }

        api.post("/account/reset-password", { user, urlToken: token })
            .then(({ data }) => {
                setLoading(false)
                alert(data.message)
                history.push("/")
            })
            .catch(error => {
                if (error.response.data) {
                    setError(error.response.data.message);
                }
                setLoading(false)
                console.log(error)
            })
    }

    return (
        <div style={styles.container}>
            <div style={styles.imageBackground} />
            <div style={styles.opacityContainer} />
            <Paper style={styles.loginContainer}>
                <Text style={styles.title}>
                    Resetar Senha
                </Text>
                <Text style={styles.text}>
                    Preencha os campos para redefinir sua senha
                </Text>
                <TextField
                    autoComplete="new-emailToken"
                    placeholder='Token'
                    style={styles.textField}
                    value={user.emailToken}
                    onChange={handleChange}
                    name='emailToken'
                    InputProps={{ style: { backgroundColor: '#f4f4f4' } }}
                />
                <TextField
                    autoComplete="new-password"
                    placeholder='Senha'
                    style={styles.textField}
                    value={user.password}
                    onChange={handleChange}
                    name='password'
                    type="password"
                    InputProps={{ style: { backgroundColor: '#f4f4f4' } }}
                />
                <TextField
                    autoComplete="new-confirmPassword"
                    placeholder='Confirme sua senha'
                    style={styles.textField}
                    value={user.confirmPassword}
                    onChange={handleChange}
                    name='confirmPassword'
                    type="password"
                    InputProps={{ style: { backgroundColor: '#f4f4f4' } }}
                />
                <Button onClick={handleResetPassword} variant='contained' size='large' style={{ marginTop: 20 }}>Alterar minha senha</Button>
            </Paper>
            {error &&
                <ErrorDialog msg={error} title='w3lib Admin' handleClose={() => setError(null)} />
            }
        </div>
    )
}

const styles = {
    container: {
        flex: 1,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#181C29',
    },
    imageBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundImage: "url('/wallpaperlogin_2.jpg')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        opacity: 0.35,
    },

    opacityContainer: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        backdropFilter: 'blur(2px)',
        WebkitBackdropFilter: 'blur(2px)'
    },
    loginContainer: {
        position: 'absolute',
        margin: 'auto',
        flexDirection: 'column',
        padding: `20px 45px 45px 45px`,
        display: 'flex',
        width: 340,
        backgroundColor: '#181C29',
        borderRadius: 10,
        border: `3px solid #8862D477`,
    },
    title: {
        marginBottom: 10,
        textAlign: "center",
        fontSize: 30,
        color: "#fdfdfd"
    },
    textField: {
        marginBottom: 10,
        borderRadius: 5,
        backgroundColor: '#D1BAFF'
    },
    text: {
        marginBottom: 40,
        textAlign: "center",
        color: "#fdfdfd"
    }
}