import { Dialog, DialogActions, DialogContent, DialogContentText, Text, Button, Box } from '../../W3libComponents'

export const ErrorDialog = (props) => {

   const { title, msg, handleClose } = props
   return (
      <Dialog
         open={true}
      >
         <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 7
         }}>
            <Text style={{ padding: 10, fontSize: 20, fontWeight: 700 }}>{title}</Text>
            <DialogContent>
               <DialogContentText>
                  {msg}
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose} color="primary" variant='contained'>Ok</Button>
            </DialogActions>
         </Box>
      </Dialog>)
}