import { Button, Text, Box } from '../../atoms/index'

export const SectionHeaderTaskDetails = (props) => {

   const {
      title = '',
      number = '',
      deleteButton = false,
      editButton = false,
      newButton = false,
      style,
      editButtonAction = () => { },
      deleteButtonAction = () => { },
      newButtonAction = () => { } } = props

   return (
      <div style={{ ...{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 20 }, ...style }}>
         <Box xs={6} sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
            <Text variant='h4' weight='900' color='secondary'>{`#${number}.`}</Text>
            <Text variant='h4' weight='800' color='primary' id="test">
               {`${title}`}<span style={{ color: '#000' }}>{`]`}</span>
            </Text>
         </Box>
         <div xs={6} style={{ textAlign: 'right', display: 'flex', alignItems: 'center' }}>
            {deleteButton &&
               <Button variant='outlined' size='small' color='secondary' style={{ marginRight: 10 }} onClick={deleteButtonAction}>
                  <Text style={{ fontSize: 12 }}>Excluir</Text>
               </Button>
            }
            {editButton &&
               <Button variant='contained' size='small' color='primary' onClick={editButtonAction} >
                  <Text style={{ fontSize: 12 }}>Editar</Text>
               </Button>
            }
            {newButton &&
               <Button variant='contained' size='small' color='primary' onClick={newButtonAction} >
                  <Text style={{ fontSize: 12 }}>Novo</Text>
               </Button>
            }
         </div>
      </div>
   )
}