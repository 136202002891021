export function transformDate(string) {
  function getCurrentMonthName(dt) {
    let monthNamelist = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Maio",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    return monthNamelist[dt.getMonth()];
  }

  let temp = new Date(string);
  let nameMonth = getCurrentMonthName(temp);
  let seconds =
    temp.getSeconds() >= 10 ? temp.getSeconds() : "0" + temp.getSeconds();
  let minutes =
    temp.getMinutes() >= 10 ? temp.getMinutes() : "0" + temp.getMinutes();
  let hour =
    temp.getHours() + 1 >= 10 ? temp.getHours() : "0" + temp.getHours();
  let year = temp.getFullYear();
  let dt = temp.getDate() >= 10 ? temp.getDate() : "0" + temp.getDate();
  let fullDate = temp.toUTCString(); // expect: Wed, 03 Nov 2021 20:17:58 GMT

  return (dt + " " + nameMonth + " " + year + " " + hour + ":" + minutes + ":" + seconds)
}
