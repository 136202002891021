import { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { AppContext } from "../../../contexts/AppContext";
import {
  Header,
  LeftMenu,
  TaskList,
  TaskDetails,
  Login,
  UserList,
  TaskNew,
  UserNew,
  UserDetails,
  CompanyList,
  CompanyNew,
  CompanyDetails,
  CompanyEdit,
  UserEdit,
  AppSettings,
  ProjectList,
  ProjectDetails,
  ProjectNew,
  ForgotPassword,
  ResetPassword,
} from "../../W3libComponents";
import { NewTaskList } from "../../pages/task/NewTaskList";

const Application = () => {
  const { user, loadingCurrentUser, requestUserInformation, checkPermission } =
    useContext(AuthContext);
  const { setLoading } = useContext(AppContext);

  const context = {
    setLoading,
    checkPermission,
  };

  useEffect(() => {
    requestUserInformation();
  }, []);

  return (
    <>
      {!user && loadingCurrentUser && <div>Loading...</div>}
      {!user && !loadingCurrentUser && (
        <Router>
          <Switch>
            <Route path="/" exact>
              <Login context={context} />
            </Route>
            <Route path="/forgot-password" exact>
              <ForgotPassword />
            </Route>
            <Route path="/reset-password/:token">
              <ResetPassword />
            </Route>
            <Route path="/*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      )}
      {user && (
        <div style={styles.container}>
          <Header context={context} />
          <div style={styles.bodyContainer}>
            <Router>
              <LeftMenu />
              <div style={styles.contentContainer}>
                <Switch>
                  {checkPermission(["task-read"]) && (
                    <Route path="/tasks" exact>
                      <TaskList />
                    </Route>
                  )}
                  {checkPermission(["task-read"]) && (
                    <Route path="/new-tasks" exact>
                      <NewTaskList />
                    </Route>
                  )}
                  {checkPermission(["task-create"]) && (
                    <Route path="/task/new" exact>
                      <TaskNew />
                    </Route>
                  )}
                  {checkPermission(["task-read"]) && (
                    <Route path="/task/:id" exact>
                      <TaskDetails />
                    </Route>
                  )}
                  {checkPermission(["user-read"]) && (
                    <Route path="/users" exact>
                      <UserList context={context} />
                    </Route>
                  )}
                  {checkPermission(["user-create"]) && (
                    <Route path="/user/new" exact>
                      <UserNew />
                    </Route>
                  )}
                  {checkPermission(["user-read"]) && (
                    <Route path="/user/:id" exact>
                      <UserDetails context={context} />
                    </Route>
                  )}
                  {checkPermission(["user-edit"]) && (
                    <Route path="/user/edit/:id" exact>
                      <UserEdit context={context} />
                    </Route>
                  )}
                  {checkPermission(["invoice-read"]) && (
                    <Route path="/invoice" exact>
                      <div>NFs</div>
                    </Route>
                  )}
                  {checkPermission(["company-read"]) && (
                    <Route path="/company" exact>
                      <CompanyList context={context} />
                    </Route>
                  )}
                  {checkPermission(["company-create"]) && (
                    <Route path="/company/new" exact>
                      <CompanyNew context={context} />
                    </Route>
                  )}
                  {checkPermission(["company-read"]) && (
                    <Route path="/company/:id" exact>
                      <CompanyDetails context={context} />
                    </Route>
                  )}
                  {checkPermission(["company-edit"]) && (
                    <Route path="/company/edit/:id" exact>
                      <CompanyEdit context={context} />
                    </Route>
                  )}
                  {checkPermission(["root"]) && (
                    <Route path="/settings" exact>
                      <AppSettings context={context} />
                    </Route>
                  )}
                  {checkPermission(["project-read"]) && (
                    <Route path="/projects" exact>
                      <ProjectList context={context} />
                    </Route>
                  )}
                  {checkPermission(["project-create"]) && (
                    <Route path="/project/new" exact>
                      <ProjectNew context={context} />
                    </Route>
                  )}
                  {checkPermission(["project-read"]) && (
                    <Route path="/project/:id" exact>
                      <ProjectDetails context={context} />
                    </Route>
                  )}
                </Switch>
              </div>
            </Router>
          </div>
        </div>
      )}
    </>
  );
};

export default Application;

const styles = {
  container: {
    minHeight: "100vh",
  },
  bodyContainer: {
    display: "flex",
    height: "100%",
    flex: 1,
    flexDirection: "row",
  },
  contentContainer: {
    display: "flex",
    flex: 1,
    height: "100%",

    minHeight: "100vh",
    flexGrow: 1,
    boxSizing: "border-box",
    backgroundColor: "#8862D411",
    padding: `50px 30px 40px 30px`,
    paddingTop: 120,
    marginLeft: 220,
  },
};
