import { Button, Paper, TextField } from "@mui/material"
import { ErrorDialog, Text } from '../../W3libComponents'
import { useContext, useState } from "react"
import api from "../../../services/api"
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../contexts";

export const ForgotPassword = () => {

    const history = useHistory()
    const { setLoading } = useContext(AppContext)
    const [user, setUser] = useState({ email: '' })
    const [error, setError] = useState(null)

    const handleChange = ({ currentTarget }) => {
        setUser({ ...user, [currentTarget.name]: currentTarget.value })
    }

    const handleSendEmail = () => {
        if (user.email === "") return
        setLoading(true)
        api.post("/account/forgot-password", user)
        .then(({data}) => {
            setLoading(false)
            history.push(`/reset-password/${data.url}`)
        })
        .catch(error => {
            if (error.response.data) {
                setError(error.response.data.message);
            }
            setLoading(false)
            console.log(error)
        })
    }

    return (
        <div style={styles.container}>
            <div style={styles.opacityContainer} />
            <div style={styles.imageBackground} />
            <Paper style={styles.loginContainer}>

                <Text style={styles.title}>
                    Esqueci minha senha
                </Text>

                <TextField
                    placeholder='E-mail'
                    style={styles.textField}
                    value={user.email}
                    onChange={handleChange}
                    name='email'
                    InputProps={{ style: { backgroundColor: '#f4f4f4' } }}
                />

                <Text style={styles.text}>
                    Coloque seu e-mail registrado e iremos enviar um token para que você possa redefinir sua senha.
                </Text>

                <Button onClick={handleSendEmail} variant='contained' size='large'>Alterar minha senha</Button>
            </Paper>
            {error &&
                <ErrorDialog msg={error} title='w3lib Admin' handleClose={() => setError(null)} />
            }
        </div>
    )
}

const styles = {
    container: {
        flex: 1,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#181C29',
    },
    imageBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundImage: "url('wallpaperlogin_2.jpg')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        opacity: 0.35,
    },
    opacityContainer: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        backdropFilter: 'blur(2px)',
        WebkitBackdropFilter: 'blur(2px)'
    },
    loginContainer: {
        position: 'absolute',
        margin: 'auto',
        flexDirection: 'column',
        padding: `20px 45px 45px 45px`,
        display: 'flex',
        width: 340,
        backgroundColor: '#181C29',
        borderRadius: 10,
        border: `3px solid #8862D477`,
    },
    title: {
        marginBottom: 60,
        textAlign: "center",
        fontSize: 30,
        color: "#fdfdfd"
    },
    textField: {
        borderRadius: 5,
        backgroundColor: '#D1BAFF'
    },
    text: {
        margin: "30px 0",
        color: "#fdfdfd"
    }
}
