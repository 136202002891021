import api from "../../../services/api";
import { useState, useEffect } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Paper, Div, SectionHeader, SectionContainer, Text, Button, Box } from "../../W3libComponents";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export const AppSettings = ({ context }) => {
    const [permissions, setPermissions] = useState([])
    const { setLoading } = context

    useEffect(() => {
        loadPermissions()
    }, [])

    const loadPermissions = () => {
        setLoading(true)
        api.get('/permission').then((response) => {
            const { groups } = response.data.permissions
            setPermissions(groups)
            setLoading(false)
        }).catch((err) => {
            setLoading(true)
            console.log(err)
        })
    }

    return (
        <SectionContainer>
            <SectionHeader
                title="Configurações"
            />
            <Paper>
                <Box sx={styles.subtitleContainer}>
                    <Text sx={styles.subtitle}>Permissões</Text>
                </Box>
                <Box>
                    {permissions.map((item, index) =>
                        <Accordion disableGutters key={`permissions${index}`}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Text sx={styles.groupTitle}>{item.name}</Text>
                            </AccordionSummary>
                            {item.permissions.map((item) =>
                                <AccordionDetails sx={styles.permissionText}>{item.description}</AccordionDetails>
                            )}
                        </Accordion>
                    )}
                </Box>
            </Paper>
        </SectionContainer>
    )
}

const styles = {
    subtitle: {
        fontWeight: '600',
        flex: 1,
        fontSize: 16
    },
    subtitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContect: 'center',
        // marginBottom: 3,
        padding: 2
    },
    groupTitle: {
        fontWeight: '700',
        fontSize: 12
    },
    permissionText: {
        fontSize: 12
    }
}