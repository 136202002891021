import { useContext, useState } from 'react'
import { AppContext } from '../../../contexts/AppContext'
import api from '../../../services/api'
import { Dialog, Button, DialogActions, DialogContent, DialogContentText, TextField, Box } from '../../W3libComponents'

export const AddHourDialog = (props) => {

    const { handleClose, handleConfirm, taskId } = props
    const { setLoading } = useContext(AppContext)
    const [minutes, setMinutes] =  useState(15)
    const [error, setError] =  useState(null)

    const handleAddHour = () => {

        if(parseInt(minutes)<15){
            setError('O tempo mínimo para atendimento de um chamado é 15 minutos')
            
            return false
        }

        setLoading(true);

        api.post(`/task/${taskId}/hour`, {minutes}).then((response) => {
            setLoading(false);
            handleConfirm(minutes)
        }).catch((err) => {
            setLoading(false)
        });
    }

    const handleChange = ({target}) => {
        
        if(parseInt(target.value)>=15){
            setError(null)
        }

        setMinutes(target.value)
    }


    return (
        <Dialog open={true}>
            <DialogContent>
                <DialogContentText>
                    Insira o tempo de atendimento deste chamado em minutos
                </DialogContentText>
                <Box>
                    <TextField 
                        value={minutes}
                        label='Minutos'
                        type='number'
                        error={error}
                        onChange={handleChange}
                        autoFocus
                        helperText={error}
                        sx={{marginTop: 3}}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant='outlined'>Cancelar</Button>
                <Button onClick={handleAddHour} color="primary" variant='contained'>Adicionar</Button>
            </DialogActions>
        </Dialog>
    )
}
