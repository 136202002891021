import api from '../../../services/api';
import { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { SectionContainer, SectionHeader, Paper, Table, TableBody, TableCell, TableRow, Text, Box, Button, UserListDialog, DetailsRow, IconButton, ConfirmDialog } from "../../W3libComponents"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const CompanyDetails = ({ context }) => {

    const { setLoading } = context
    const [company, setCompany] = useState({
        employers: []
    })
    const [showUserListDialog, setShowUserListDialog] = useState(false)
    const { id } = useParams();
    const history = useHistory()

    useEffect(() => {

        setLoading(true)

        api.get(`/company/${id}`).then((response) => {
            setLoading(false)
            const filteredEmployers = response.data.company.employers.filter(user => user.active)
            setCompany({...response.data.company, employers: filteredEmployers});
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        });
    }, []);

    const handleAddEmploye = (ids) => {
        setShowUserListDialog(false)
        setLoading(true)

        api.post(`/user/${ids[0]}/company/${id}`).then(response => {
            setCompany(response.data.company)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    const handleRemoveEmployee = (userId) => {
        setShowUserListDialog(false)
        setLoading(true)

        api.delete(`/user/${userId}/company/${id}`).then(response => {
            setCompany(response.data.company)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    return (
        <>
            <SectionContainer >
                <SectionHeader title={company.name} editButton editButtonAction={() => history.push(`/company/edit/${id}`)} />
                <Paper sx={styles.detailsContainer}>
                    <Table>
                        <TableBody>
                            <DetailsRow label='Website' value={company.urlCompanyPage} />
                        </TableBody>
                    </Table>
                </Paper>
                <Paper sx={styles.contactsContainer}>
                    <Box sx={styles.subtitleContainer}>
                        <Text sx={styles.subtitle}>Contatos</Text>
                        <Button onClick={() => setShowUserListDialog(true)} variant='outlined' size='small'>Adicionar</Button>
                    </Box>
                    <Table size="small">
                        <TableBody>
                            {company.employers.map((employee, index) => <ContactRow
                                key={`contactrow${index}`}
                                employee={employee}
                                company={company}
                                handleRemoveEmployee={handleRemoveEmployee}
                            />)}
                        </TableBody>
                    </Table>
                </Paper>
            </SectionContainer>
            {showUserListDialog && <UserListDialog handleClose={() => setShowUserListDialog(false)} handleSubmit={handleAddEmploye} />}

        </>
    )
}

const ContactRow = ({ employee, handleRemoveEmployee, company }) => {

    const { name, _id } = employee
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const history = useHistory()

    return (
        <>
            <TableRow hover>
                <TableCell align="left">
                    {name}
                </TableCell>
                <TableCell align="center" sx={{ width: 25 }}>
                    <IconButton onClick={() => history.push(`/user/${_id}`)}>
                        <VisibilityIcon sx={{ color: '#afafaf' }} />
                    </IconButton>
                </TableCell>
                <TableCell align="center" sx={{ width: 25 }}>
                    <IconButton onClick={() => setShowConfirmDialog(true)}>
                        <HighlightOffIcon sx={{ color: '#afafaf' }} />
                    </IconButton>
                </TableCell>
                
            </TableRow >
            {showConfirmDialog &&
                <ConfirmDialog
                    title="Remover Contato"
                    question={`Deseja remover o contato ${name} da empresa ${company.name}?`}
                    handleClose={() => setShowConfirmDialog(false)}
                    handleConfirm={() => {
                        setShowConfirmDialog(false)
                        handleRemoveEmployee(_id)
                    }}
                />
            }
        </>
    )
}

const styles = {
    detailsContainer: {

    },
    contactsContainer: {
        padding: 2,
        marginTop: 2
    },
    subtitle: {
        fontWeight: '600',
        flex: 1,
        fontSize: 16
    },
    subtitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContect: 'center',
        marginBottom: 3
    }
}