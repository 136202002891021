import { statusLabelData } from "../utils/tasks"

export const useStatusLabel = (props) => {
    const {
        currentStatus = '',
        dropDown = false,
        dropDownOptions = [],
        dropDownOptionAction = () => { },
        arrow = "",
        arrowAnimation = () => { },
        arrowId = ""
    } = props


    const status = statusLabelData(currentStatus)
    const priorityArrow = dropDown ? document.querySelector(`#${arrowId}`) : null
    const dropDownOptionsFiltered = dropDownOptions.filter(item => item.value !== status.value)

    return { dropDown, dropDownOptionAction, arrow, arrowAnimation, arrowId, status, priorityArrow, dropDownOptionsFiltered, }
}