import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import api from "../../../services/api";
import {
  Div,
  Paper,
  SectionHeader,
  SectionContainer,
  Grid,
  TextField,
  Button,
  Text,
  Box,
  Checkbox,
} from "../../W3libComponents";

export const UserEdit = ({ context }) => {
  const { id } = useParams();
  const [user, setUser] = useState({ name: "", email: "", employee: false });
  const { setLoading } = context;
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    api
      .get(`/user/${id}`)
      .then((response) => {
        setUser(response.data.user);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSave = () => {
    setLoading(true);
    api
      .patch(`/user/${id}`, { user })
      .then((response) => {
        history.push(`/user/${id}`);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <SectionContainer>
      <SectionHeader title={user.name} />
      <Div>
        <Paper
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            padding: 20,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Nome"
                refs="name"
                name="name"
                margin="dense"
                value={user.name}
                fullWidth
                autoFocus
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="email"
                variant="outlined"
                label="Email"
                refs="email"
                name="email"
                margin="dense"
                value={user.email}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", flexDirection: "column", gap: 4 }}
            >
              <Box style={{ display: "flex", gap: 4 }}>
                <Checkbox
                  name="employee"
                  checked={user.employee}
                  onChange={({ target }) => {
                    handleChange({
                      target: { name: target.name, value: target.checked },
                    });
                  }}
                  sx={{ padding: 0 }}
                />
                <Text>Colaborador</Text>
              </Box>
              <Text style={{ fontSize: 13, color: "#888" }}>
                Colaboradores podem ver dados de tempo investido nas tarefas.
              </Text>
            </Grid>
            <Grid item xs={12}>
              <Div style={{ justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  style={{ bottom: 0 }}
                  onClick={handleSave}
                >
                  <Text style={{ fontSize: 12 }}>Salvar</Text>
                </Button>
              </Div>
            </Grid>
          </Grid>
        </Paper>
      </Div>
    </SectionContainer>
  );
};
