import {TableRow, TableCell} from '../../W3libComponents'

export const DetailsRow = ({ label, value }) => (
    <>
        {value &&
            <TableRow>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    {label}
                </TableCell>
                <TableCell align="left" >
                    {value}
                </TableCell>
            </TableRow>
        }
    </>
)