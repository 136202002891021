import api from "../../../services/api";
import { useState, useContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { Div, Grid, Paper, SectionHeader, SectionContainer, TextField, Button, Box, Text, CompanySelect, UserSelect } from "../../W3libComponents";

export const ProjectNew = ({ context }) => {
    const { setLoading } = context
    const { user } = useContext(AuthContext);
    const history = useHistory();

    const [project, setProject] = useState({});

    const [documents, setDocuments] = useState([])
    const [errors, setErrors] = useState({})
    const [checkErrors, setCheckErrors] = useState(false)

    const handleChange = (event) => {
        const { name, value } = event.target;

        setProject({
            ...project,
            [name]: value,
        });
    };

    const handleSave = () => {
        setLoading(true)

        api.post('/project', {project}).then(response=>{
            setLoading(false)
            const {project} = response.data
            history.push(`/project/${project._id}`)
        }).catch(err=>{
            setLoading(false)
        })

    };

    return (
        <SectionContainer>
            <SectionHeader title="Novo Projeto" />
            <Paper sx={{ padding: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    label="Título"
                                    name="title"
                                    fullWidth
                                    required
                                    error={errors?.title}
                                    helperText={errors?.title?.error}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CompanySelect handleSelect={handleChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <UserSelect handleSelect={handleChange} label='Gerente do Projeto' name='assignTo' />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    minRows={5}
                                    maxRows={10}
                                    label="Descrição"
                                    value={project?.description}
                                    multiline
                                    name='description'
                                    required
                                    error={errors?.description}
                                    helperText={errors?.description?.error}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='contained' onClick={handleSave}> Salvar</Button>
                    </Grid>
                </Grid>
            </Paper>
        </SectionContainer>
    );
};